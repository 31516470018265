export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const DISPLAY_SNACKBAR = 'DISPLAY_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

// MANAGE MENU
export const UPDATE_ORGANIZATION_DATUM = 'UPDATE_ORGANIZATION_DATUM';
export const INIT_ORGANIZATION_SEARCHDATA = 'INIT_ORGANIZATION_SEARCHDATA';
export const UPDATE_ORGANIZATION_SEARCHDATA = 'UPDATE_ORGANIZATION_SEARCHDATA';
export const UPDATE_ORGANIZATION_ERROR_SEARCHDATA = 'UPDATE_ORGANIZATION_ERROR_SEARCHDATA';
export const UPDATE_DEVICE_MAKER_DATUM = 'UPDATE_DEVICE_MAKER_DATUM';
export const INIT_DEVICE_MAKER_SEARCHDATA = 'INIT_DEVICE_MAKER_SEARCHDATA';
export const UPDATE_DEVICE_MAKER_SEARCHDATA = 'UPDATE_DEVICE_MAKER_SEARCHDATA';
export const UPDATE_DEVICE_MAKER_ERROR_SEARCHDATA = 'UPDATE_DEVICE_MAKER_ERROR_SEARCHDATA';
export const UPDATE_SITE_DATUM = 'UPDATE_SITE_DATUM';
export const INIT_SITE_SEARCHDATA = 'INIT_SITE_SEARCHDATA';
export const UPDATE_SITE_SEARCHDATA = 'UPDATE_SITE_SEARCHDATA';
export const UPDATE_SITE_ERROR_SEARCHDATA = 'UPDATE_SITE_ERROR_SEARCHDATA';
export const UPDATE_FACILITY_DATUM = 'UPDATE_FACILITY_DATUM';
export const INIT_FACILITY_SEARCHDATA = 'INIT_FACILITY_SEARCHDATA';
export const UPDATE_FACILITY_SEARCHDATA = 'UPDATE_FACILITY_SEARCHDATA';
export const UPDATE_FACILITY_ERROR_SEARCHDATA = 'UPDATE_FACILITY_ERROR_SEARCHDATA';
export const UPDATE_DEVICE_DATUM = 'UPDATE_DEVICE_DATUM';
export const INIT_DEVICE_SEARCHDATA = 'INIT_DEVICE_SEARCHDATA';
export const UPDATE_DEVICE_SEARCHDATA = 'UPDATE_DEVICE_SEARCHDATA';
export const UPDATE_DEVICE_ERROR_SEARCHDATA = 'UPDATE_DEVICE_ERROR_SEARCHDATA';
export const UPDATE_CHILD_DEVICE_DATUM = 'UPDATE_CHILD_DEVICE_DATUM';
export const INIT_CHILD_DEVICE_SEARCHDATA = 'INIT_CHILD_DEVICE_SEARCHDATA';
export const UPDATE_CHILD_DEVICE_SEARCHDATA = 'UPDATE_CHILD_DEVICE_SEARCHDATA';
export const UPDATE_CHILD_DEVICE_ERROR_SEARCHDATA = 'UPDATE_CHILD_DEVICE_ERROR_SEARCHDATA';
export const UPLOAD_CSV_ONSEARCH = 'UPLOAD_CSV_ONSEARCH';
export const UPDATE_SITE_DATUM_MTGB = 'UPDATE_SITE_DATUM_MTGB';
export const INIT_SITE_SEARCHDATA_MTGB = 'INIT_SITE_SEARCHDATA_MTGB';
export const UPDATE_SITE_SEARCHDATA_MTGB = 'UPDATE_SITE_SEARCHDATA_MTGB';
export const UPDATE_SITE_ERROR_SEARCHDATA_MTGB = 'UPDATE_SITE_ERROR_SEARCHDATA_MTGB';
export const UPDATE_EMPLOYEE_DATUM_MTGB = 'UPDATE_EMPLOYEE_DATUM_MTGB';
export const INIT_EMPLOYEE_SEARCHDATA_MTGB = 'INIT_EMPLOYEE_SEARCHDATA_MTGB';
export const UPDATE_EMPLOYEE_SEARCHDATA_MTGB = 'UPDATE_EMPLOYEE_SEARCHDATA_MTGB';
export const UPDATE_EMPLOYEE_ERROR_SEARCHDATA_MTGB = 'UPDATE_EMPLOYEE_ERROR_SEARCHDATA_MTGB';
export const UPDATE_ACCOUNT_DATUM = 'UPDATE_ACCOUNT_DATUM';
export const INIT_ACCOUNT_SEARCHDATA = 'INIT_ACCOUNT_SEARCHDATA';
export const UPDATE_ACCOUNT_SEARCHDATA = 'UPDATE_ACCOUNT_SEARCHDATA';
export const UPDATE_ACCOUNT_ERROR_SEARCHDATA = 'UPDATE_ACCOUNT_ERROR_SEARCHDATA';
export const UPDATE_COOPERATION_SYSTEM_DATUM = 'UPDATE_COOPERATION_SYSTEM_DATUM';
export const INIT_COOPERATION_SYSTEM_SEARCHDATA = 'INIT_COOPERATION_SYSTEM_SEARCHDATA';
export const UPDATE_COOPERATION_SYSTEM_SEARCHDATA = 'UPDATE_COOPERATION_SYSTEM_SEARCHDATA';
export const UPDATE_COOPERATION_SYSTEM_ERROR_SEARCHDATA = 'UPDATE_COOPERATION_SYSTEM_ERROR_SEARCHDATA';
export const UPDATE_DIAMOND_CONNECTION_DATUM = 'UPDATE_DIAMOND_CONNECTION_DATUM';
export const INIT_DIAMOND_CONNECTION_SEARCHDATA = 'INIT_DIAMOND_CONNECTION_SEARCHDATA';
export const UPDATE_DIAMOND_CONNECTION_SEARCHDATA = 'UPDATE_DIAMOND_CONNECTION_SEARCHDATA';
export const UPDATE_DIAMOND_CONNECTION_ERROR_SEARCHDATA = 'UPDATE_DIAMOND_CONNECTION_ERROR_SEARCHDATA';
export const UPDATE_DIAMOND_FACILITY_DATUM = 'UPDATE_DIAMOND_FACILITY_DATUM';
export const INIT_DIAMOND_FACILITY_SEARCHDATA = 'INIT_DIAMOND_FACILITY_SEARCHDATA';
export const UPDATE_DIAMOND_FACILITY_SEARCHDATA = 'UPDATE_DIAMOND_FACILITY_SEARCHDATA';
export const UPDATE_DIAMOND_FACILITY_ERROR_SEARCHDATA = 'UPDATE_DIAMOND_FACILITY_ERROR_SEARCHDATA';
export const UPDATE_DIAMOND_DEVICE_DATUM = 'UPDATE_DIAMOND_DEVICE_DATUM';
export const INIT_DIAMOND_DEVICE_SEARCHDATA = 'INIT_DIAMOND_DEVICE_SEARCHDATA';
export const UPDATE_DIAMOND_DEVICE_SEARCHDATA = 'UPDATE_DIAMOND_DEVICE_SEARCHDATA';
export const UPDATE_DIAMOND_DEVICE_ERROR_SEARCHDATA = 'UPDATE_DIAMOND_DEVICE_ERROR_SEARCHDATA';
export const UPDATE_MASTER_COOPERATION_SYSTEM_DATUM = 'UPDATE_MASTER_COOPERATION_SYSTEM_DATUM';
export const INIT_MASTER_COOPERATION_SYSTEM_SEARCHDATA = 'INIT_MASTER_COOPERATION_SYSTEM_SEARCHDATA';
export const UPDATE_MASTER_COOPERATION_SYSTEM_SEARCHDATA = 'UPDATE_MASTER_COOPERATION_SYSTEM_SEARCHDATA';
export const UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_SEARCHDATA = 'UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_SEARCHDATA';

// EDIT PAGE
export const INIT_ORGANIZATION_DATA = 'INIT_ORGANIZATION_DATA';
export const UPDATE_ORGANIZATION_DATA = 'UPDATE_ORGANIZATION_DATA';
export const UPDATE_ORGANIZATION_ERROR_DATA = 'UPDATE_ORGANIZATION_ERROR_DATA';
export const LOAD_ORGANIZATION_DATA = 'LOAD_ORGANIZATION_DATA';
export const INIT_DEVICE_MAKER_DATA = 'INIT_DEVICE_MAKER_DATA';
export const UPDATE_DEVICE_MAKER_DATA = 'UPDATE_DEVICE_MAKER_DATA';
export const UPDATE_DEVICE_MAKER_ERROR_DATA = 'UPDATE_DEVICE_MAKER_ERROR_DATA';
export const LOAD_DEVICE_MAKER_DATA = 'LOAD_DEVICE_MAKER_DATA';
export const INIT_SITE_DATA = 'INIT_SITE_DATA';
export const UPDATE_SITE_DATA = 'UPDATE_SITE_DATA';
export const UPDATE_SITE_ERROR_DATA = 'UPDATE_SITE_ERROR_DATA';
export const LOAD_SITE_DATA = 'LOAD_SITE_DATA';
export const INIT_DEVICE_DATA = 'INIT_DEVICE_DATA';
export const UPDATE_DEVICE_DATA = 'UPDATE_DEVICE_DATA';
export const UPDATE_DEVICE_ERROR_DATA = 'UPDATE_DEVICE_ERROR_DATA';
export const LOAD_DEVICE_DATA = 'LOAD_DEVICE_DATA';
export const INIT_FACILITY_DATA = 'INIT_FACILITY_DATA';
export const UPDATE_FACILITY_DATA = 'UPDATE_FACILITY_DATA';
export const UPDATE_FACILITY_ERROR_DATA = 'UPDATE_FACILITY_ERROR_DATA';
export const LOAD_FACILITY_DATA = 'LOAD_FACILITY_DATA';
export const INIT_CHILD_DEVICE_DATA = 'INIT_CHILD_DEVICE_DATA';
export const UPDATE_CHILD_DEVICE_DATA = 'UPDATE_CHILD_DEVICE_DATA';
export const UPDATE_CHILD_DEVICE_ERROR_DATA = 'UPDATE_CHILD_DEVICE_ERROR_DATA';
export const LOAD_CHILD_DEVICE_DATA = 'LOAD_CHILD_DEVICE_DATA';
export const INIT_ACCOUNT_DATA = 'INIT_ACCOUNT_DATA';
export const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';
export const UPDATE_ACCOUNT_ERROR_DATA = 'UPDATE_ACCOUNT_ERROR_DATA';
export const LOAD_ACCOUNT_DATA = 'LOAD_ACCOUNT_DATA';
export const INIT_COOPERATION_SYSTEM_DATA = 'INIT_COOPERATION_SYSTEM_DATA';
export const UPDATE_COOPERATION_SYSTEM_DATA = 'UPDATE_COOPERATION_SYSTEM_DATA';
export const UPDATE_COOPERATION_SYSTEM_ERROR_DATA = 'UPDATE_COOPERATION_SYSTEM_ERROR_DATA';
export const LOAD_COOPERATION_SYSTEM_DATA = 'LOAD_COOPERATION_SYSTEM_DATA';
export const INIT_DIAMOND_CONNECTION_DATA = 'INIT_DIAMOND_CONNECTION_DATA';
export const UPDATE_DIAMOND_CONNECTION_DATA = 'UPDATE_DIAMOND_CONNECTION_DATA';
export const UPDATE_DIAMOND_CONNECTION_ERROR_DATA = 'UPDATE_DIAMOND_CONNECTION_ERROR_DATA';
export const LOAD_DIAMOND_CONNECTION_DATA = 'LOAD_DIAMOND_CONNECTION_DATA';
export const INIT_DIAMOND_FACILITY_DATA = 'INIT_DIAMOND_FACILITY_DATA';
export const UPDATE_DIAMOND_FACILITY_DATA = 'UPDATE_DIAMOND_FACILITY_DATA';
export const UPDATE_DIAMOND_FACILITY_ERROR_DATA = 'UPDATE_DIAMOND_FACILITY_ERROR_DATA';
export const LOAD_DIAMOND_FACILITY_DATA = 'LOAD_DIAMOND_FACILITY_DATA';
export const INIT_DIAMOND_DEVICE_DATA = 'INIT_DIAMOND_DEVICE_DATA';
export const UPDATE_DIAMOND_DEVICE_DATA = 'UPDATE_DIAMOND_DEVICE_DATA';
export const UPDATE_DIAMOND_DEVICE_ERROR_DATA = 'UPDATE_DIAMOND_DEVICE_ERROR_DATA';
export const LOAD_DIAMOND_DEVICE_DATA = 'LOAD_DIAMOND_DEVICE_DATA';
export const INIT_MASTER_COOPERATION_SYSTEM_DATA = 'INIT_MASTER_COOPERATION_SYSTEM_DATA';
export const UPDATE_MASTER_COOPERATION_SYSTEM_DATA = 'UPDATE_MASTER_COOPERATION_SYSTEM_DATA';
export const UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_DATA = 'UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_DATA';
export const LOAD_MASTER_COOPERATION_SYSTEM_DATA = 'LOAD_MASTER_COOPERATION_SYSTEM_DATA';

// SYSLOG
export const UPDATE_SYSLOG_DATA = 'UPDATE_SYSLOG_DATA';
export const UPDATE_SYSLOG_ERROR_DATA = 'UPDATE_SYSLOG_ERROR_DATA';
export const LOAD_SYSLOG_DATA = 'LOAD_SYSLOG_DATA';
export const SET_SYSLOG = 'SET_SYSLOG_DATA';

// MONITOR
export const INIT_SUMMARY_MONITOR_CHILD_DEVICE_DATUM = 'INIT_SUMMARY_MONITOR_CHILD_DEVICE_DATUM';
export const LOAD_SUMMARY_MONITOR_CHILD_DEVICE_DATUM = 'LOAD_SUMMARY_MONITOR_CHILD_DEVICE_DATUM';
export const INIT_MONITOR_CHILD_DEVICE_LOGS = 'INIT_MONITOR_CHILD_DEVICE_LOGS';
export const LOAD_MONITOR_CHILD_DEVICE_LOGS = 'LOAD_MONITOR_CHILD_DEVICE_LOGS';
export const INIT_MONITOR_RAW_LOGS = 'INIT_MONITOR_RAW_LOGS';
export const LOAD_MONITOR_RAW_LOGS = 'LOAD_MONITOR_RAW_LOGS';

// LIST_DEVICE
export const UPDATE_LIST_DEVICE_DATA = 'UPDATE_LIST_DEVICE_DATA';
export const UPDATE_LIST_DEVICE_ERROR_DATA = 'UPDATE_LIST_DEVICE_ERROR_DATA';
export const LOAD_LIST_DEVICE_DATA = 'LOAD_LIST_DEVICE_DATA';
export const SET_LIST_DEVICE = 'SET_LIST_DEVICE_DATA';

export const SET_AUTH_USER = 'SET_AUTH_USER';
export const UPDATE_AUTH_USER = 'UPDATE_AUTH_USER';
export const UPDATE_LOAD_USER = 'UPDATE_LOAD_USER';
export const SEND_FORGET_PASSWORD_EMAIL = 'SEND_FORGET_PASSWORD_EMAIL';
export const PASSWD_VISIBILITY = 'PASSWD_VISIBILITY';

export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER_SUCCESS = 'SIGNIN_GITHUB_USER_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'SET_TASK_CURRENT_USER';
export const SET_TASKS_DATA = 'SET_TASKS_DATA';
export const SET_TASK_LIST_DATA = 'SET_TASK_LIST_DATA';
export const ADD_TASK = 'ADD_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const ADD_TASK_LIST = 'ADD_TASK_LIST';
export const UPDATE_TASK_LIST = 'UPDATE_TASK_LIST';
export const DELETE_TASK_LIST = 'DELETE_TASK_LIST';
export const SET_TASK_DETAIL = 'SET_TASK_DETAIL';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const TOGGLE_SIDEBAR_COLLAPSED = 'TOGGLE_SIDEBAR_COLLAPSED';
export const GET_TASKS_COUNTS = 'GET_TASKS_COUNTS';

//mail app
export const GET_LABELS_LIST = 'GET_LABELS_LIST';
export const GET_CONNECTIONS_LIST = 'GET_CONNECTIONS_LIST';
export const GET_MAILS_LIST = 'GET_MAILS_LIST';
export const UPDATE_MAIL_FOLDER = 'UPDATE_MAIL_FOLDER';
export const UPDATE_MAIL_LABEL = 'UPDATE_MAIL_LABEL';
export const UPDATE_FAVORITE_STATUS = 'UPDATE_FAVORITE_STATUS';
export const UPDATE_READ_STATUS = 'UPDATE_READ_STATUS';
export const UPDATE_IMPORTANT_STATUS = 'UPDATE_IMPORTANT_STATUS';
export const COMPOSE_MAIL = 'COMPOSE_MAIL';
export const SET_FILTER_TYPE = 'SET_FILTER_TYPE';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'UPDATE_SELECTED_MAIL';
export const NULLIFY_SELECTED_MAIL = 'NULLIFY_SELECTED_MAIL';
export const REPLY_TO_MAIL = 'REPLY_TO_MAIL';
export const GET_MAIL_COUNTS = 'GET_MAIL_COUNTS';
export const ADD_LABEL = 'ADD_LABEL';
export const ADD_CONNECTION = 'ADD_CONNECTION';
export const REMOVE_CONNECTION = 'REMOVE_CONNECTION';

export const SET_CHAT_USERS = 'SET_CHAT_USERS';
export const SET_CONTACT_USERS = 'SET_CONTACT_USERS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CONVERSATION_DATA = 'SET_CONVERSATION_DATA';
export const SEND_NEW_CHAT_MESSAGE = 'SEND_NEW_CHAT_MESSAGE';
export const SEND_NEW_MEDIA_MESSAGE = 'SEND_NEW_MEDIA_MESSAGE';

//Contact App
export const GET_CONTACTS_LIST = 'GET_CONTACTS_LIST';
export const SET_CURRENT_CONTACT = 'SET_CURRENT_CONTACT';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const UPDATE_STARRED_STATUS = 'UPDATE_STARRED_STATUS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT_LABEL = 'UPDATE_CONTACT_LABEL';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const GET_CONTACT_COUNTS = 'GET_CONTACT_COUNTS';
export const UPDATE_LABEL_ITEM = 'UPDATE_LABEL_ITEM';
export const DELETE_LABEL_ITEM = 'DELETE_LABEL_ITEM';

export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const GET_FEED_POSTS = 'GET_FEED_POSTS';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';

// Users Module
export const GET_USERS = 'GET_USERS';
export const ADD_USER = 'ADD_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BULK_USERS = 'DELETE_BULK_USERS';
