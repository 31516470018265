import { useTheme } from '@emotion/react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import useClasses from 'theme/useClasses';

const styles = (theme) => ({
  listFooterRoot: {
    padding: 10,
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'center',
  },
  footerWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
    color: theme.palette.text.secondary,
  },
});

const GridFooter = ({ loading, footerText }) => {
  const theme = useTheme();
  const classes = useClasses(styles(theme));
  if (loading) {
    return (
      <div className={classes.footerWrapper}>
        <CircularProgress size={16} />
        <span className="ml-2">Loading...</span>
      </div>
    );
  } else {
    return (
      <div className={clsx(classes.listFooterRoot, 'Cmt-list-footer')}>
        <p>{footerText}</p>
      </div>
    );
  }
};

GridFooter.propTypes = {
  loading: PropTypes.bool,
  footerText: PropTypes.string,
};

GridFooter.defaultProps = {
  loading: false,
  footerText: '',
};

export default GridFooter;
