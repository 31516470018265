import Auth from './Auth';
import Common from './Common';
import Edit from './Edit';
import Manage from './Manage';
import Monitor from './Monitor';
import { combineReducers } from 'redux';

const init = (routerReducer) =>
  combineReducers({
    router: routerReducer,
    common: Common,
    auth: Auth,
    manage: Manage,
    edit: Edit,
    monitor: Monitor,
  });

export default init;
