import { forceJsonParse, getStringFromDate } from 'utils/commonHelper';
import {
  INIT_SUMMARY_MONITOR_CHILD_DEVICE_DATUM,
  LOAD_SUMMARY_MONITOR_CHILD_DEVICE_DATUM,
  INIT_MONITOR_CHILD_DEVICE_LOGS,
  LOAD_MONITOR_CHILD_DEVICE_LOGS,
  INIT_MONITOR_RAW_LOGS,
  LOAD_MONITOR_RAW_LOGS,
} from '../../constants/ActionTypes';

export const initSummaryMonitorChildDeviceDatum = (datum) => {
  return (dispatch) => {
    dispatch({
      type: INIT_SUMMARY_MONITOR_CHILD_DEVICE_DATUM,
      payload: datum,
    });
  };
};

export const updateSummaryMonitorChildDeviceDatumLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_SUMMARY_MONITOR_CHILD_DEVICE_DATUM,
      payload: { value, message },
    });
  };
};

export const initMonitorChildDeviceLogs = (logs) => {
  return (dispatch) => {
    dispatch({
      type: INIT_MONITOR_CHILD_DEVICE_LOGS,
      payload: { logs, latestDateTime: logs?.[0]?.TimeGenerated || '' },
    });
  };
};

export const updateMonitorChildDeviceLogsLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_MONITOR_CHILD_DEVICE_LOGS,
      payload: { value, message },
    });
  };
};

export const initMonitorRawLogs = (logs) => {
  return (dispatch) => {
    // メール送信ログの場合は、表示に最適化したプロパティを追加
    const addedLogs = Array.isArray(logs?.logs)
      ? logs?.logs.map((log) => {
          const parseMessage = forceJsonParse(log.Message);
          const sendResult = parseMessage?.sent_alert_mail?.send_result === 'success' ? '送信成功' : parseMessage?.sent_alert_mail?.send_result === 'error' ? '送信失敗' : '';
          const mailTo = Array.isArray(parseMessage?.sent_alert_mail?.mail_to) ? parseMessage?.sent_alert_mail?.mail_to.join('\n') : '';
          const datetime = isNaN(new Date(log.TimeGenerated).getDate()) ? '-' : getStringFromDate(new Date(log.TimeGenerated), 'YYYY/MM/DD hh:mm:ss.SSS');
          const date = isNaN(new Date(log.TimeGenerated).getDate()) ? '-' : getStringFromDate(new Date(log.TimeGenerated), 'YYYY/MM/DD');
          const time = isNaN(new Date(log.TimeGenerated).getDate()) ? '-' : getStringFromDate(new Date(log.TimeGenerated), 'hh:mm:ss.SSS');
          const body = parseMessage?.sent_alert_mail?.body;
          const subject = parseMessage?.sent_alert_mail?.subject;
          const bodyMes = !body
            ? ''
            : !!body.im_diamond_connections_rowKey
            ? `RoboHUB-IMから通知が届きました。
レベル: ${body?.level}
ロボット連携システムID: ${body?.im_cooperation_systems_rowKey || '-'}
ロボット連携システム: ${body?.im_cooperation_systems_name || '-'}
設備キー: ${body?.im_child_devices_rowKey || '-'}
設備名: ${body?.im_child_devices_name || '-'}
施設敷地: ${body?.im_sites_name || '-'}
施設名: ${body?.im_facilities_name || '-'}
設備フロア: -
発生日時: ${datetime}
エラーコード: ${body?.error_code || '-'}
概要: ${subject || '-'}
詳細: ${body?.contents || '-'}
三菱設備ID: ${body?.im_diamond_devices_rowKey || '-'}
三菱設備名称: ${body?.im_diamond_devices_name || '-'}
三菱施設ID: ${body?.im_diamond_facilities_rowKey || '-'}
三菱施設名称: ${body?.im_diamond_facilities_name || '-'}
三菱契約ID:${body?.im_diamond_connections_rowKey || '-'}
三菱契約名称: ${body?.im_diamond_connections_name || '-'}
`
            : `RoboHUB-IMから通知が届きました。
レベル: ${body?.level}
ロボット連携システムID: ${body?.im_cooperation_systems_rowKey || '-'}
ロボット連携システム: ${body?.im_cooperation_systems_name || '-'}
設備キー: ${body?.im_child_devices_rowKey || '-'}
設備名: ${body?.im_child_devices_name || '-'}
施設敷地: ${body?.im_sites_name || '-'}
施設名: ${body?.im_facilities_name || '-'}
設備フロア: -
発生日時: ${datetime}
エラーコード: ${body?.error_code || '-'}
概要: ${subject || '-'}
詳細: ${body?.contents || '-'}
`;
          return {
            ...log,
            sendResult,
            mailTo,
            mailLogLevel: body?.level,
            subject: `【${body?.level}】` + subject,
            body: bodyMes,
            csvObj: { ...body, datetime, date, time, subject, floor: '-', sendResult, mailTo },
          };
        })
      : [];
    dispatch({
      type: INIT_MONITOR_RAW_LOGS,
      payload: { logKind: logs?.logKind, logs: addedLogs, latestDateTime: addedLogs[0]?.TimeGenerated || '' },
    });
  };
};

export const updateMonitorRawLogsLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_MONITOR_RAW_LOGS,
      payload: { value, message },
    });
  };
};
