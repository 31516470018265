import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import configureStore, { history } from './redux/store';
import Route from './routes';
import React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

export const store = configureStore();

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <AppContextProvider>
        <AppWrapper>
          <Route />
        </AppWrapper>
      </AppContextProvider>
    </Router>
  </Provider>
);

export default App;
