import CmtImage from '@coremat/CmtImage';
import { flags } from './data';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { useTheme } from '@emotion/react';
import useClasses from 'theme/useClasses';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    '& .flag': {
      fontSize: 30,
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },
});

const LanguageItem = ({ language, onClick }) => {
  const { locale, name } = language;
  const theme = useTheme();
  const classes = useClasses(styles(theme));

  return (
    <Box className={clsx(classes.root, 'pointer')} onClick={() => onClick(language)}>
      <CmtImage src={flags[locale]} />
      <Box ml={3}>{name}</Box>
    </Box>
  );
};

export default LanguageItem;
