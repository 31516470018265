import reducers from '../reducers';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { configureStore as redux_configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

let history;

const configureStore = (initialState = {}) => {
  const store = redux_configureStore({
    reducer: reducers(routerReducer),
    preloadedState: initialState,
    middleware: [routerMiddleware, thunk],
    devTools: process.env.REACT_APP_ENV === 'LOCAL',
  });
  history = createReduxHistory(store);
  return store;
};

export default configureStore;
export { history };
