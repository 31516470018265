import { useEffect, useState } from 'react';

/**
 * awaitで任意のミリ秒sleepする
 * @returns
 */
export const sleep = (mSeconds) => new Promise((resolve) => setTimeout(() => resolve(), mSeconds));

/**
 * 配列をn個ずつの配列の塊に再構成する関数
 * @param {array} array 再構成する配列
 * @param {number} number n個ずつの配列の塊にするn
 * @returns
 */
export const arraySliceByNumber = (array, number) => {
  const length = Math.ceil(array.length / number);
  return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
};

/**
 * jsonだったらパースして返却、strならそのまま返却
 * @param {string} str
 * @returns
 */
export const forceJsonParse = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};

/**
 * キーを大文字に変換
 * @param {*}
 * @returns
 */
export const keysToUpper = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toUpper(k)] = keysToUpper(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToUpper(i);
    });
  }

  return o;
};

/**
 * キーを小文字に変換
 * @param {*} o
 * @returns
 */
export const keysToLower = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toLower(k)] = keysToLower(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToLower(i);
    });
  }

  return o;
};

/**
 * キャメルケースをスネークケースに変換
 * @param {*} o
 * @returns
 */
export const keysToSnake = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toSnake(k)] = keysToSnake(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToSnake(i);
    });
  }

  return o;
};

/**
 * スネークケースをキャメルケースに変換
 * @param {*} o
 * @returns
 */
export const keysToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return keysToCamel(i);
    });
  }

  return o;
};

const isObject = (o) => {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

const isArray = (a) => {
  return Array.isArray(a);
};

export const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

export const toSnake = (s) => {
  return s.replace(/([A-Z])/g, (st) => {
    return '_' + st.charAt(0).toLowerCase();
  });
};

export const toUpper = (s) => {
  return s.toUpperCase();
};

export const toLower = (s) => {
  return s.toLowerCase();
};

/**
 * 空チェック
 * @param {*} value
 * @returns
 */
export const isValueExists = (value) => (value !== undefined && value !== null && value !== '' && value !== 'none' ? true : false);

/**
 * ユニークID生成（マイクロ秒単位で乱数生成）
 * @param {*} myStrong
 * @returns {string} hexの文字列
 */
export const getUniqueStr = (myStrong) => {
  let strong = 10000;
  if (myStrong) strong = myStrong;
  return Math.floor(strong * Math.random()).toString(16) + new Date().getTime().toString(16);
};

/**
 * 日付から文字列に変換する関数
 * @param {Date} date
 * @returns
 */
export const getStringFromDate = (date, format_str = 'YYYY/MM/DD hh:mm:ss +09:00') => {
  try {
    let year_str = date.getFullYear();
    //月だけ+1すること
    let month_str = 1 + date.getMonth();
    let day_str = date.getDate();
    let hour_str = date.getHours();
    let minute_str = date.getMinutes();
    let second_str = date.getSeconds();
    let millisecond_str = date.getMilliseconds();

    month_str = ('0' + month_str).slice(-2);
    day_str = ('0' + day_str).slice(-2);
    hour_str = ('0' + hour_str).slice(-2);
    minute_str = ('0' + minute_str).slice(-2);
    second_str = ('0' + second_str).slice(-2);
    millisecond_str = ('00' + millisecond_str).slice(-3);

    format_str = format_str.replace(/YYYY/g, year_str);
    format_str = format_str.replace(/MM/g, month_str);
    format_str = format_str.replace(/DD/g, day_str);
    format_str = format_str.replace(/hh/g, hour_str);
    format_str = format_str.replace(/mm/g, minute_str);
    format_str = format_str.replace(/ss/g, second_str);
    format_str = format_str.replace(/SSS/g, millisecond_str);

    return format_str;
  } catch (e) {
    this.context?.log.error('error: %o', e);
    return undefined;
  }
};

/**
 * ascii to base64エンコード関数
 * @param {String} str
 * @returns
 */
export const base64Encode = (str) => {
  const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let e = [],
    pad = '',
    c;
  c = str.length % 3;
  if (c > 0) {
    while (c++ < 3) {
      pad += '=';
      str += '\0';
    }
  }
  for (c = 0; c < str.length; c += 3) {
    const o1 = str.charCodeAt(c);
    const o2 = str.charCodeAt(c + 1);
    const o3 = str.charCodeAt(c + 2);
    const bits = (o1 << 16) | (o2 << 8) | o3;
    const h1 = (bits >> 18) & 0x3f;
    const h2 = (bits >> 12) & 0x3f;
    const h3 = (bits >> 6) & 0x3f;
    const h4 = bits & 0x3f;
    e[c / 3] = b64.charAt(h1) + b64.charAt(h2) + b64.charAt(h3) + b64.charAt(h4);
  }
  str = e.join('');
  str = str.slice(0, str.length - pad.length) + pad;
  return str;
};

/**
 * base64 to asciiデコード関数
 * @param {base64} str
 * @returns
 */
export const base64Decode = (str) => {
  // eslint-disable-next-line no-console, eqeqeq
  if (!/^[a-z0-9+/]+={0,2}$/i.test(str) || str.length % 4 != 0) console.log('base64Decode: Not base64 string');
  const b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  let d = [];
  for (let c = 0; c < str.length; c += 4) {
    const h1 = b64.indexOf(str.charAt(c));
    const h2 = b64.indexOf(str.charAt(c + 1));
    const h3 = b64.indexOf(str.charAt(c + 2));
    const h4 = b64.indexOf(str.charAt(c + 3));
    const bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;
    const o1 = (bits >>> 16) & 0xff;
    const o2 = (bits >>> 8) & 0xff;
    const o3 = bits & 0xff;
    d[c / 4] = String.fromCodePoint(o1, o2, o3);
    // eslint-disable-next-line eqeqeq
    if (h4 == 0x40) d[c / 4] = String.fromCodePoint(o1, o2);
    // eslint-disable-next-line eqeqeq
    if (h3 == 0x40) d[c / 4] = String.fromCodePoint(o1);
  }
  str = d.join('');
  return str;
};

export const capitalizeFLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

export const isValidEmail = (value) => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000);
};

export const linkify = (inputText) => {
  let replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z0-9\\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
};

export const geValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return 'http://' + url;
    }
    return 'https://' + url;
  }

  return url;
};

export const useDebounce = (value, delay) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState('');

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below).
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value, delay],
  );

  return debouncedValue;
};
