import {
  INIT_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_ERROR_DATA,
  LOAD_ORGANIZATION_DATA,
  INIT_DEVICE_MAKER_DATA,
  UPDATE_DEVICE_MAKER_DATA,
  UPDATE_DEVICE_MAKER_ERROR_DATA,
  LOAD_DEVICE_MAKER_DATA,
  INIT_SITE_DATA,
  UPDATE_SITE_DATA,
  UPDATE_SITE_ERROR_DATA,
  LOAD_SITE_DATA,
  INIT_DEVICE_DATA,
  UPDATE_DEVICE_DATA,
  UPDATE_DEVICE_ERROR_DATA,
  LOAD_DEVICE_DATA,
  INIT_FACILITY_DATA,
  UPDATE_FACILITY_DATA,
  UPDATE_FACILITY_ERROR_DATA,
  LOAD_FACILITY_DATA,
  INIT_CHILD_DEVICE_DATA,
  UPDATE_CHILD_DEVICE_DATA,
  UPDATE_CHILD_DEVICE_ERROR_DATA,
  LOAD_CHILD_DEVICE_DATA,
  INIT_ACCOUNT_DATA,
  UPDATE_ACCOUNT_DATA,
  UPDATE_ACCOUNT_ERROR_DATA,
  LOAD_ACCOUNT_DATA,
  INIT_COOPERATION_SYSTEM_DATA,
  UPDATE_COOPERATION_SYSTEM_DATA,
  UPDATE_COOPERATION_SYSTEM_ERROR_DATA,
  LOAD_COOPERATION_SYSTEM_DATA,
  INIT_DIAMOND_CONNECTION_DATA,
  UPDATE_DIAMOND_CONNECTION_DATA,
  UPDATE_DIAMOND_CONNECTION_ERROR_DATA,
  LOAD_DIAMOND_CONNECTION_DATA,
  INIT_DIAMOND_FACILITY_DATA,
  UPDATE_DIAMOND_FACILITY_DATA,
  UPDATE_DIAMOND_FACILITY_ERROR_DATA,
  LOAD_DIAMOND_FACILITY_DATA,
  INIT_DIAMOND_DEVICE_DATA,
  UPDATE_DIAMOND_DEVICE_DATA,
  UPDATE_DIAMOND_DEVICE_ERROR_DATA,
  LOAD_DIAMOND_DEVICE_DATA,
  INIT_MASTER_COOPERATION_SYSTEM_DATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_DATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_DATA,
  LOAD_MASTER_COOPERATION_SYSTEM_DATA,
} from 'constants/ActionTypes';

const INIT_STATE = {
  organizationData: null,
  organizationErrorData: null,
  organizationDataLoaded: { value: false, message: 'データを読み込み中です…' },
  deviceMakerData: null,
  deviceMakerErrorData: null,
  deviceMakerDataLoaded: { value: false, message: 'データを読み込み中です…' },
  siteData: null,
  siteErrorData: null,
  siteDataLoaded: { value: false, message: 'データを読み込み中です…' },
  deviceData: null,
  deviceErrorData: null,
  deviceDataLoaded: { value: false, message: 'データを読み込み中です…' },
  facilityData: null,
  facilityErrorData: null,
  facilityDataLoaded: { value: false, message: 'データを読み込み中です…' },
  childDeviceData: null,
  childDeviceErrorData: null,
  childDeviceDataLoaded: { value: false, message: 'データを読み込み中です…' },
  accountData: null,
  accountDefaultData: null,
  accountErrorData: null,
  accountDataLoaded: { value: false, message: 'データを読み込み中です…' },
  cooperationSystemData: null,
  cooperationSystemErrorData: null,
  cooperationSystemDataLoaded: { value: false, message: 'データを読み込み中です…' },
  diamondConnectionData: null,
  diamondConnectionErrorData: null,
  diamondConnectionDataLoaded: { value: false, message: 'データを読み込み中です…' },
  diamondFacilityData: null,
  diamondFacilityErrorData: null,
  diamondFacilityDataLoaded: { value: false, message: 'データを読み込み中です…' },
  diamondDeviceData: null,
  diamondDeviceErrorData: null,
  diamondDeviceDataLoaded: { value: false, message: 'データを読み込み中です…' },
  masterCooperationSystemData: null,
  masterCooperationSystemErrorData: null,
  masterCooperationSystemDataLoaded: { value: false, message: 'データを読み込み中です…' },
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORGANIZATION_DATA: {
      return {
        ...state,
        organizationData: action.payload,
        organizationErrorData: {},
      };
    }
    case UPDATE_ORGANIZATION_DATA: {
      return {
        ...state,
        organizationData: { ...state.organizationData, ...action.payload },
      };
    }
    case UPDATE_ORGANIZATION_ERROR_DATA: {
      return {
        ...state,
        organizationErrorData: { ...state.organizationErrorData, ...action.payload },
      };
    }
    case LOAD_ORGANIZATION_DATA: {
      return {
        ...state,
        organizationDataLoaded: action.payload,
      };
    }

    case INIT_DEVICE_MAKER_DATA: {
      return {
        ...state,
        deviceMakerData: action.payload,
        deviceMakerErrorData: {},
      };
    }
    case UPDATE_DEVICE_MAKER_DATA: {
      return {
        ...state,
        deviceMakerData: { ...state.deviceMakerData, ...action.payload },
      };
    }
    case UPDATE_DEVICE_MAKER_ERROR_DATA: {
      return {
        ...state,
        deviceMakerErrorData: { ...state.deviceMakerErrorData, ...action.payload },
      };
    }
    case LOAD_DEVICE_MAKER_DATA: {
      return {
        ...state,
        deviceMakerDataLoaded: action.payload,
      };
    }
    case INIT_SITE_DATA: {
      return {
        ...state,
        siteData: action.payload,
        siteErrorData: {},
      };
    }
    case UPDATE_SITE_DATA: {
      return {
        ...state,
        siteData: { ...state.siteData, ...action.payload },
      };
    }
    case UPDATE_SITE_ERROR_DATA: {
      return {
        ...state,
        siteErrorData: { ...state.siteErrorData, ...action.payload },
      };
    }
    case LOAD_SITE_DATA: {
      return {
        ...state,
        siteDataLoaded: action.payload,
      };
    }
    case INIT_DEVICE_DATA: {
      return {
        ...state,
        deviceData: action.payload,
        deviceErrorData: {},
      };
    }
    case UPDATE_DEVICE_DATA: {
      return {
        ...state,
        deviceData: { ...state.deviceData, ...action.payload },
      };
    }
    case UPDATE_DEVICE_ERROR_DATA: {
      return {
        ...state,
        deviceErrorData: { ...state.deviceErrorData, ...action.payload },
      };
    }
    case LOAD_DEVICE_DATA: {
      return {
        ...state,
        deviceDataLoaded: action.payload,
      };
    }
    case INIT_FACILITY_DATA: {
      return {
        ...state,
        facilityData: action.payload,
        facilityErrorData: {},
      };
    }
    case UPDATE_FACILITY_DATA: {
      return {
        ...state,
        facilityData: { ...state.facilityData, ...action.payload },
      };
    }
    case UPDATE_FACILITY_ERROR_DATA: {
      return {
        ...state,
        facilityErrorData: { ...state.facilityErrorData, ...action.payload },
      };
    }
    case LOAD_FACILITY_DATA: {
      return {
        ...state,
        facilityDataLoaded: action.payload,
      };
    }
    case INIT_CHILD_DEVICE_DATA: {
      return {
        ...state,
        childDeviceData: action.payload,
        childDeviceErrorData: {},
      };
    }
    case UPDATE_CHILD_DEVICE_DATA: {
      return {
        ...state,
        childDeviceData: { ...state.childDeviceData, ...action.payload },
      };
    }
    case UPDATE_CHILD_DEVICE_ERROR_DATA: {
      return {
        ...state,
        childDeviceErrorData: { ...state.childDeviceErrorData, ...action.payload },
      };
    }
    case LOAD_CHILD_DEVICE_DATA: {
      return {
        ...state,
        childDeviceDataLoaded: action.payload,
      };
    }
    case INIT_ACCOUNT_DATA: {
      return {
        ...state,
        accountData: action.payload,
        accountDefaultData: action.payload, // 20231222 ロボット施設管理者でログインし、すでに登録済の施設内ユーザのデータ編集時、ロボット施設管理者に権限を変更できてしまうのは良くない（新規登録時だけは作成可だけど、更新時はNGという仕様なので）ので、データ取得時のデフォルト状態の権限で判断するために保持
        accountErrorData: {},
      };
    }
    case UPDATE_ACCOUNT_DATA: {
      return {
        ...state,
        accountData: { ...state.accountData, ...action.payload },
      };
    }
    case UPDATE_ACCOUNT_ERROR_DATA: {
      return {
        ...state,
        accountErrorData: { ...state.accountErrorData, ...action.payload },
      };
    }
    case LOAD_ACCOUNT_DATA: {
      return {
        ...state,
        accountDataLoaded: action.payload,
      };
    }
    case INIT_COOPERATION_SYSTEM_DATA: {
      return {
        ...state,
        cooperationSystemData: action.payload,
        cooperationSystemErrorData: {},
      };
    }
    case UPDATE_COOPERATION_SYSTEM_DATA: {
      return {
        ...state,
        cooperationSystemData: { ...state.cooperationSystemData, ...action.payload },
      };
    }
    case UPDATE_COOPERATION_SYSTEM_ERROR_DATA: {
      return {
        ...state,
        cooperationSystemErrorData: { ...state.cooperationSystemErrorData, ...action.payload },
      };
    }
    case LOAD_COOPERATION_SYSTEM_DATA: {
      return {
        ...state,
        cooperationSystemDataLoaded: action.payload,
      };
    }
    case INIT_DIAMOND_CONNECTION_DATA: {
      return {
        ...state,
        diamondConnectionData: action.payload,
        diamondConnectionErrorData: {},
      };
    }
    case UPDATE_DIAMOND_CONNECTION_DATA: {
      return {
        ...state,
        diamondConnectionData: { ...state.diamondConnectionData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_CONNECTION_ERROR_DATA: {
      return {
        ...state,
        diamondConnectionErrorData: { ...state.diamondConnectionErrorData, ...action.payload },
      };
    }
    case LOAD_DIAMOND_CONNECTION_DATA: {
      return {
        ...state,
        diamondConnectionDataLoaded: action.payload,
      };
    }
    case INIT_DIAMOND_FACILITY_DATA: {
      return {
        ...state,
        diamondFacilityData: action.payload,
        diamondFacilityErrorData: {},
      };
    }
    case UPDATE_DIAMOND_FACILITY_DATA: {
      return {
        ...state,
        diamondFacilityData: { ...state.diamondFacilityData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_FACILITY_ERROR_DATA: {
      return {
        ...state,
        diamondFacilityErrorData: { ...state.diamondFacilityErrorData, ...action.payload },
      };
    }
    case LOAD_DIAMOND_FACILITY_DATA: {
      return {
        ...state,
        diamondFacilityDataLoaded: action.payload,
      };
    }
    case INIT_DIAMOND_DEVICE_DATA: {
      return {
        ...state,
        diamondDeviceData: action.payload,
        diamondDeviceErrorData: {},
      };
    }
    case UPDATE_DIAMOND_DEVICE_DATA: {
      return {
        ...state,
        diamondDeviceData: { ...state.diamondDeviceData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_DEVICE_ERROR_DATA: {
      return {
        ...state,
        diamondDeviceErrorData: { ...state.diamondDeviceErrorData, ...action.payload },
      };
    }
    case LOAD_DIAMOND_DEVICE_DATA: {
      return {
        ...state,
        diamondDeviceDataLoaded: action.payload,
      };
    }
    case INIT_MASTER_COOPERATION_SYSTEM_DATA: {
      return {
        ...state,
        masterCooperationSystemData: action.payload,
        masterCooperationSystemErrorData: {},
      };
    }
    case UPDATE_MASTER_COOPERATION_SYSTEM_DATA: {
      return {
        ...state,
        masterCooperationSystemData: { ...state.masterCooperationSystemData, ...action.payload },
      };
    }
    case UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_DATA: {
      return {
        ...state,
        masterCooperationSystemErrorData: { ...state.masterCooperationSystemErrorData, ...action.payload },
      };
    }
    case LOAD_MASTER_COOPERATION_SYSTEM_DATA: {
      return {
        ...state,
        masterCooperationSystemDataLoaded: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
