import { useTheme } from '@emotion/react';
import CachedIcon from '@mui/icons-material/Cached';
import { Box, Button, Divider } from '@mui/material';
import React from 'react';
import useClasses from 'theme/useClasses';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  loginSection: {
    display: 'flex',
    alignItems: 'center',
    '& > .MuiDivider-root': {
      height: 14,
      marginLeft: 8,
      marginRight: 8,
      backgroundColor: theme.palette.text.secondary,
    },
  },
});

const HeaderLogin = () => {
  const theme = useTheme();
  const classes = useClasses(styles(theme));
  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" color="warning.main">
        <CachedIcon fontSize="small" />
        <div className="ml-3">This is primary alert-check it out!</div>
      </Box>
      <div className={classes.loginSection}>
        <Button className="Cmt-btn" size="small">
          Login
        </Button>
        <Divider className="Cmt-divider" orientation="vertical" />
        <Button className="Cmt-btn" size="small">
          Sign up
        </Button>
      </div>
    </div>
  );
};

export default HeaderLogin;
