import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, DISPLAY_SNACKBAR, CLOSE_SNACKBAR } from '../../constants/ActionTypes';

export const fetchSuccess = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};

export const fetchError = (error) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const displaySnackbar = (message) => {
  return (dispatch, getState) => {
    const { common } = getState();
    // すでにメッセージ表示中の場合は、closeしてから再表示
    if (common.message) {
      dispatch({
        type: CLOSE_SNACKBAR,
      });
    }
    // setTimeoutを0で使用し非同期にして、一度close処理を完了させる
    setTimeout(() => {
      dispatch({
        type: DISPLAY_SNACKBAR,
        payload: message,
      });
    }, 0);
  };
};

export const closeSnackbar = () => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_SNACKBAR,
    });
  };
};
