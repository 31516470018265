// sessionStorageへ値の出し入れlocalStorage
class sS {
  set = (key, value) => sessionStorage.setItem(key, value);
  get = (key) => {
    return this.getSessionStorage(key);
  };

  del = (key) => this.removeSessionStorage(key);

  getSessionStorage = (key) => {
    const ret = sessionStorage.getItem(key);
    if (ret !== null) {
      return ret;
    }
    return null;
  };

  removeSessionStorage = (key) => {
    const ret = sessionStorage.getItem(key);
    if (ret !== null) {
      return sessionStorage.removeItem(key);
    }
    return null;
  };

  getLocalUserAuthData = () => {
    return {
      isLoggedIn: this.get('isLoggedIn'),
      user_id: this.get('id_token'),
      token: this.get('login_token'),
      organizations_id: this.get('organizations_id'),
      organizations: this.get('organizations'),
      mail: this.get('mail'),
      name: this.get('name'),
      kana: this.get('kana'),
      role: this.get('role'),
    };
  };

  delLocalUserAuthData = () => {
    this.del('id_token');
    this.del('login_token');
    this.del('organizations_id');
    this.del('organizations');
    this.del('name');
    this.del('kana');
    this.del('mail');
    this.del('role');
  };
}

const sSInstance = new sS();
export default sSInstance;
