import { CardContent } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import useClasses from 'theme/useClasses';

const styles = () => ({
  root: {
    padding: 24,
  },
});

const CmtCardContent = ({ children, className, ...rest }) => {
  const classes = useClasses(styles());

  return (
    <CardContent className={clsx(classes.root, 'Cmt-card-content', className)} {...rest}>
      {children}
    </CardContent>
  );
};

export default CmtCardContent;
