import { OutlinedInput, Select } from '@mui/material';
import { thingMenus } from '../../../constants/SelectMenus';

// 行ごとに配列グループにして格納
export const editObj = [
  [
    {
      name: 'rowKey',
      label: '設備キー(rowKey)',
      editable: true,
      init: '',
      required: false,
      type: 'text',
      component: OutlinedInput,
      tooltiptext: 'このレコードの一意のキー。変更はできません',
      disabledComponent: true,
      sx: { width: '400px' },
    },
  ],
  [
    { name: 'name', label: '設備名称', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '', sx: { width: '400px' } },
    { name: 'kana', label: '設備名称カナ', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '', sx: { width: '400px' } },
  ],
  [
    {
      name: 'thing',
      label: '設備種類',
      editable: true,
      init: 'none',
      required: true,
      type: 'text',
      component: Select,
      menuItems: thingMenus,
      tooltiptext: '',
      sx: { width: '300px' },
    },
    {
      name: 'device_id',
      label: '識別子',
      editable: true,
      init: '',
      required: true,
      type: 'text',
      component: OutlinedInput,
      tooltiptext: '',
      sx: { width: '300px' },
    },
    {
      name: 'available_floors',
      label: '有効階',
      editable: true,
      init: '',
      required: true,
      type: 'text',
      component: OutlinedInput,
      tooltiptext: '',
      sx: { width: '374px' },
    },
    {
      name: 'call_number',
      label: 'シンプルシーケンス用内線番号',
      editable: true,
      init: '',
      required: false,
      type: 'text',
      component: OutlinedInput,
      tooltiptext: 'シンプルシーケンスで内線通報を行う際に使用される内線電話番号です',
      sx: { width: '374px' },
    },
    {
      name: 'forced_unlock',
      label: 'セキュリティゲート基本動作設定',
      editable: true,
      init: '2',
      required: false,
      type: 'text',
      component: Select,
      menuItems: [
        { name: '通常モード', value: '2' },
        { name: '強制解錠モード', value: '1' },
      ],
      tooltiptext: 'セキュリティゲートの基本動作設定を行います。強制解錠モードにした場合は、ゲートのモードにかかわらず、強制解錠モードで通過要求を行います',
      sx: { width: '300px' },
    },
    {
      name: 'operable_flg',
      label: 'セキュリティゲート一斉開放モード時設定',
      editable: true,
      init: '1',
      required: false,
      type: 'text',
      component: Select,
      menuItems: [
        { name: '使用可', value: '1' },
        { name: '使用不可', value: '2' },
      ],
      tooltiptext:
        'セキュリティゲートの一斉開放モード時設定を行います。使用不可にした場合は、セキュリティゲートが一斉開放モードに切り替わった際に、ロボットの通過要求にはエラーを返却します',
      sx: { width: '300px' },
    },
  ],
];

export const timeoutObj = {
  nc: [
    {
      no: 1,
      name: 'ncCallDepRes',
      tooltipText: `IO盤に「出発用ナースコール要求」を送信してから、IO盤から「出発用ナースコール応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 2,
      name: 'ncCallArrRes',
      tooltipText: `IO盤に「到着用ナースコール要求」を送信してから、IO盤から「到着用ナースコール応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
  ],
  sp: [
    {
      no: 1,
      name: 'spCallDepRes',
      tooltipText: `IO盤に「出発用スピーカー要求」を送信してから、IO盤から「出発用スピーカー応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 2,
      name: 'spCallArrRes',
      tooltipText: `IO盤に「到着用スピーカー要求」を送信してから、IO盤から「到着用スピーカー応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
  ],
  afa: [
    {
      no: 1,
      name: 'afaAlmOffAns',
      tooltipText: `連携システムに「自火報発報アラートNORMAL」を送信してから、連携システムから「自火報発報アラートNORMAL受信応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
  ],
  ad: [
    {
      no: 1,
      name: 'adOpenDoorRes',
      tooltipText: `IO盤に「扉開要求」を送信してから、IO盤から「扉開要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 2,
      name: 'adStatusOn',
      tooltipText: `IO盤に「扉開要求応答」を送信してから、IO盤から「戸開状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は60秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '60',
      limitTryCount: '3',
    },
    {
      no: 3,
      name: 'adPassAccept',
      tooltipText: `連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 4,
      name: 'adPassComplete',
      tooltipText: `連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 5,
      name: 'adStatusOff',
      tooltipText: `IO盤に「扉閉要求」を送信してから、IO盤から「戸閉状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
  ],
  elv: [
    {
      no: 1,
      name: 'elvLinkedResOn',
      tooltipText: `IO盤に「連動要求」を送信してから、IO盤から「連動要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 2,
      name: 'elvInterLocking',
      tooltipText: `IO盤から「連動要求応答」を受信してから、IO盤から「連動状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は300秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '300',
      limitTryCount: '0',
    },
    {
      no: 3,
      name: 'elvBrdgCallElevatorRes',
      tooltipText: `【乗車】IO盤に「呼出フロア指定」を送信してから、IO盤から「呼出フロア指定応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 4,
      name: 'elvBrdgStatusFloor',
      tooltipText: `【乗車】IO盤に「呼出フロア指定応答」を送信してから、IO盤から「かご階移動開始状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 5,
      name: 'elvBrdgStatusDoorOpenOn',
      tooltipText: `【乗車】IO盤から「かご階移動開始状態」を受信してから、IO盤から「戸開状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 6,
      name: 'elvBrdgDoorOpenRes',
      tooltipText: `【乗車】IO盤に「戸開要求」を送信してから、IO盤から「戸開要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 7,
      name: 'elvBrdgPassAccept',
      tooltipText: `【乗車】連携システムに「乗り込み要求」を送信してから、連携システムから「乗り込み要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 8,
      name: 'elvBrdgPassComplete',
      tooltipText: `【乗車】連携システムに「乗り込み要求」を送信してから、連携システムから「乗り込み完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 9,
      name: 'elvBrdgStatusDoorOpenOff',
      tooltipText: `【乗車】IO盤に「戸閉要求」を送信してから、IO盤から「戸閉状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は30秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '30',
      limitTryCount: '3',
    },
    {
      no: 10,
      name: 'elvAltgCallElevatorRes',
      tooltipText: `【降車】IO盤に「呼出フロア指定」を送信してから、IO盤から「呼出フロア指定応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 11,
      name: 'elvAltgStatusFloor',
      tooltipText: `【降車】IO盤に「呼出フロア指定応答」を送信してから、IO盤から「かご階移動開始状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 12,
      name: 'elvAltgStatusDoorOpenOn',
      tooltipText: `【降車】IO盤から「かご階移動開始状態」を受信してから、IO盤から「戸開状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 13,
      name: 'elvAltgDoorOpenRes',
      tooltipText: `【降車】IO盤に「戸開要求」を送信してから、IO盤から「戸開要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 14,
      name: 'elvAltgPassAccept',
      tooltipText: `【降車】連携システムに「降車要求」を送信してから、連携システムから「降車要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 15,
      name: 'elvAltgPassComplete',
      tooltipText: `【降車】連携システムに「降車要求」を送信してから、連携システムから「降車完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 16,
      name: 'elvAltgStatusDoorOpenOff',
      tooltipText: `【降車】IO盤に「戸閉要求」を送信してから、IO盤から「戸閉状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は30秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '30',
      limitTryCount: '3',
    },
    {
      no: 17,
      name: 'elvLinkedResOff',
      tooltipText: `IO盤に「連動OFF要求」を送信してから、IO盤から「連動OFF要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 18,
      name: 'elvInService',
      tooltipText: `IO盤から「連動OFF要求応答」を受信してから、IO盤から「通常モード状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は60秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '60',
      limitTryCount: '3',
    },
  ],
  tp: [
    {
      no: 1,
      name: 'tpCallRes',
      tooltipText: `【通常シーケンス】IO盤に「内線通報要求」を送信してから、IO盤から「内線通報要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 2,
      name: 'tpResult',
      tooltipText: `【通常シーケンス】IO盤から「内線通報要求応答」を受信してから、IO盤から「内線通報結果」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 3,
      name: 'tpSpCallRes',
      tooltipText: `【シンプルシーケンス】IO盤に「内線通報要求」を送信してから、IO盤から「内線通報要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 4,
      name: 'tpSpResult',
      tooltipText: `【シンプルシーケンス】IO盤から「内線通報要求応答」を受信してから、IO盤から「内線通報結果」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '0',
    },
  ],
  wps: [
    {
      no: 1,
      name: 'wpsChargeResOn',
      tooltipText: `IO盤に「給電開始要求」を送信してから、IO盤から「給電開始要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は20秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '20',
      limitTryCount: '3',
    },
    {
      no: 2,
      name: 'wpsChargeReqOff',
      tooltipText: `連携システムに「給電開始要求応答」を送信してから、連携システムから「給電停止要求」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は7200秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '7200',
      limitTryCount: '0',
    },
    {
      no: 3,
      name: 'wpsChargeResOff',
      tooltipText: `IO盤に「給電停止要求」を送信してから、IO盤から「給電停止要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は20秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '20',
      limitTryCount: '3',
    },
  ],
  sg: [
    {
      no: 1,
      name: 'sgEnterDirectionOff',
      tooltipText: `【通行許可モード入場】連携システムから「セキュリティゲート開扉要求」を受信してから、IO盤から「退場方向の通行モードのOFF」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は900秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '900',
      limitTryCount: '0',
    },
    {
      no: 2,
      name: 'sgEnterOpenRes',
      tooltipText: `【通行許可モード入場】IO盤に「戸開要求」を送信してから、IO盤から「戸開要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 3,
      name: 'sgEnterGateOpen',
      tooltipText: `【通行許可モード入場】IO盤から「戸開要求応答」を受信してから、IO盤から「戸開完了状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は30秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '30',
      limitTryCount: '3',
    },
    {
      no: 4,
      name: 'sgEnterPassAccept',
      tooltipText: `【通行許可モード入場】連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 5,
      name: 'sgEnterPassComplete',
      tooltipText: `【通行許可モード入場】連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 6,
      name: 'sgExitDirectionOff',
      tooltipText: `【通行許可モード退場】連携システムから「セキュリティゲート開扉要求」を受信してから、IO盤から「入場方向の通行モードのOFF」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は900秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '900',
      limitTryCount: '0',
    },
    {
      no: 7,
      name: 'sgExitOpenRes',
      tooltipText: `【通行許可モード退場】IO盤に「戸開要求」を送信してから、IO盤から「戸開要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 8,
      name: 'sgExitGateOpen',
      tooltipText: `【通行許可モード退場】IO盤から「戸開要求応答」を受信してから、IO盤から「戸開完了状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は30秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '30',
      limitTryCount: '3',
    },
    {
      no: 9,
      name: 'sgExitPassAccept',
      tooltipText: `【通行許可モード退場】連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 10,
      name: 'sgExitPassComplete',
      tooltipText: `【通行許可モード退場】連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 11,
      name: 'sgFuSimulResOn',
      tooltipText: `【強制解錠モード】IO盤に「一斉開放要求」を送信してから、IO盤から「一斉開放要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 12,
      name: 'sgFuFlapOpen',
      tooltipText: `【強制解錠モード】IO盤から「一斉開放要求応答」を送信してから、IO盤から「戸開状態」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は30秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '30',
      limitTryCount: '3',
    },
    {
      no: 13,
      name: 'sgFuPassAccept',
      tooltipText: `【強制解錠モード】連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 14,
      name: 'sgFuPassComplete',
      tooltipText: `【強制解錠モード】連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 15,
      name: 'sgFuSimulResOff',
      tooltipText: `【強制解錠モード】IO盤に「一斉開放OFF要求」を送信してから、IO盤から「一斉開放OFF要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 16,
      name: 'sgAdEnterPassAccept',
      tooltipText: `【自動ドアモード入場】連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 17,
      name: 'sgAdEnterPassComplete',
      tooltipText: `【自動ドアモード入場】連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 18,
      name: 'sgAdExitPassAccept',
      tooltipText: `【自動ドアモード退場】連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 19,
      name: 'sgAdExitPassComplete',
      tooltipText: `【自動ドアモード退場】連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
    {
      no: 20,
      name: 'sgSoPassAccept',
      tooltipText: `【一斉解放モード】連携システムに「通過要求」を送信してから、連携システムから「通過要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '10',
      limitTryCount: '3',
    },
    {
      no: 21,
      name: 'sgSoPassComplete',
      tooltipText: `【一斉解放モード】連携システムから「通過要求応答」を受信してから、連携システムから「通過完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
      デフォルトのタイムアウト時間は120秒、リトライ回数は設定できません。`,
      minTime: '2',
      maxTime: '999999',
      timeoutDelay: '120',
      limitTryCount: '0',
    },
  ],
};
