import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ja from 'date-fns/locale/ja';
import React, { useContext, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import SnackBarControl from 'routes/Common/SnackBarControl';

const AppWrapper = ({ children }) => {
  const { theme, locale, direction } = useContext(AppContext);

  const currentAppLocale = AppLocale[locale.locale];
  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(theme)}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <CssBaseline />
            <AppLayout>
              <SnackBarControl>{children}</SnackBarControl>
            </AppLayout>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
