import {
  UPDATE_ORGANIZATION_DATUM,
  INIT_ORGANIZATION_SEARCHDATA,
  UPDATE_ORGANIZATION_SEARCHDATA,
  UPDATE_ORGANIZATION_ERROR_SEARCHDATA,
  UPDATE_DEVICE_MAKER_DATUM,
  INIT_DEVICE_MAKER_SEARCHDATA,
  UPDATE_DEVICE_MAKER_SEARCHDATA,
  UPDATE_DEVICE_MAKER_ERROR_SEARCHDATA,
  UPDATE_SITE_DATUM,
  INIT_SITE_SEARCHDATA,
  UPDATE_SITE_SEARCHDATA,
  UPDATE_SITE_ERROR_SEARCHDATA,
  UPDATE_FACILITY_DATUM,
  INIT_FACILITY_SEARCHDATA,
  UPDATE_FACILITY_SEARCHDATA,
  UPDATE_FACILITY_ERROR_SEARCHDATA,
  UPDATE_DEVICE_DATUM,
  INIT_DEVICE_SEARCHDATA,
  UPDATE_DEVICE_SEARCHDATA,
  UPDATE_DEVICE_ERROR_SEARCHDATA,
  UPDATE_CHILD_DEVICE_DATUM,
  INIT_CHILD_DEVICE_SEARCHDATA,
  UPDATE_CHILD_DEVICE_SEARCHDATA,
  UPDATE_CHILD_DEVICE_ERROR_SEARCHDATA,
  UPDATE_ACCOUNT_DATUM,
  INIT_ACCOUNT_SEARCHDATA,
  UPDATE_ACCOUNT_SEARCHDATA,
  UPDATE_ACCOUNT_ERROR_SEARCHDATA,
  UPDATE_COOPERATION_SYSTEM_DATUM,
  INIT_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_COOPERATION_SYSTEM_ERROR_SEARCHDATA,
  UPDATE_DIAMOND_CONNECTION_DATUM,
  INIT_DIAMOND_CONNECTION_SEARCHDATA,
  UPDATE_DIAMOND_CONNECTION_SEARCHDATA,
  UPDATE_DIAMOND_CONNECTION_ERROR_SEARCHDATA,
  UPDATE_DIAMOND_FACILITY_DATUM,
  INIT_DIAMOND_FACILITY_SEARCHDATA,
  UPDATE_DIAMOND_FACILITY_SEARCHDATA,
  UPDATE_DIAMOND_FACILITY_ERROR_SEARCHDATA,
  UPDATE_DIAMOND_DEVICE_DATUM,
  INIT_DIAMOND_DEVICE_SEARCHDATA,
  UPDATE_DIAMOND_DEVICE_SEARCHDATA,
  UPDATE_DIAMOND_DEVICE_ERROR_SEARCHDATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_DATUM,
  INIT_MASTER_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_SEARCHDATA,
} from 'constants/ActionTypes';

export const setOrganizationDatum = (organizationDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORGANIZATION_DATUM,
      payload: organizationDatum,
    });
  };
};

export const initOrganizationSearchData = (organizationSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_ORGANIZATION_SEARCHDATA,
      payload: organizationSearchData,
    });
  };
};

export const updateOrganizationSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORGANIZATION_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateOrganizationErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORGANIZATION_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setDeviceMakerDatum = (deviceMakerDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_MAKER_DATUM,
      payload: deviceMakerDatum,
    });
  };
};

export const initDeviceMakerSearchData = (deviceMakerSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DEVICE_MAKER_SEARCHDATA,
      payload: deviceMakerSearchData,
    });
  };
};

export const updateDeviceMakerSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_MAKER_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateDeviceMakerErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_MAKER_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setSiteDatum = (siteDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SITE_DATUM,
      payload: siteDatum,
    });
  };
};

export const initSiteSearchData = (siteSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_SITE_SEARCHDATA,
      payload: siteSearchData,
    });
  };
};

export const updateSiteSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SITE_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateSiteErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SITE_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setFacilityDatum = (facilityDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FACILITY_DATUM,
      payload: facilityDatum,
    });
  };
};

export const initFacilitySearchData = (facilitySearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_FACILITY_SEARCHDATA,
      payload: facilitySearchData,
    });
  };
};

export const updateFacilitySearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FACILITY_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateFacilityErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FACILITY_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setDeviceDatum = (sensorMasterDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_DATUM,
      payload: sensorMasterDatum,
    });
  };
};

export const initDeviceSearchData = (sensorMasterSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DEVICE_SEARCHDATA,
      payload: sensorMasterSearchData,
    });
  };
};

export const updateDeviceSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateDeviceErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setChildDeviceDatum = (sensorChildDeviceDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHILD_DEVICE_DATUM,
      payload: sensorChildDeviceDatum,
    });
  };
};

export const initChildDeviceSearchData = (childDeviceSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_CHILD_DEVICE_SEARCHDATA,
      payload: childDeviceSearchData,
    });
  };
};

export const updateChildDeviceSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHILD_DEVICE_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateChildDeviceErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHILD_DEVICE_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setAccountDatum = (accountDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT_DATUM,
      payload: accountDatum,
    });
  };
};

export const initAccountSearchData = (accountSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_ACCOUNT_SEARCHDATA,
      payload: accountSearchData,
    });
  };
};

export const updateAccountSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateAccountErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setCooperationSystemDatum = (cooperationSystemDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COOPERATION_SYSTEM_DATUM,
      payload: cooperationSystemDatum,
    });
  };
};

export const initCooperationSystemSearchData = (cooperationSystemSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_COOPERATION_SYSTEM_SEARCHDATA,
      payload: cooperationSystemSearchData,
    });
  };
};

export const updateCooperationSystemSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COOPERATION_SYSTEM_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateCooperationSystemErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COOPERATION_SYSTEM_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setDiamondConnectionDatum = (diamondConnectionDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_CONNECTION_DATUM,
      payload: diamondConnectionDatum,
    });
  };
};

export const initDiamondConnectionSearchData = (diamondConnectionSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DIAMOND_CONNECTION_SEARCHDATA,
      payload: diamondConnectionSearchData,
    });
  };
};

export const updateDiamondConnectionSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_CONNECTION_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateDiamondConnectionErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_CONNECTION_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setDiamondFacilityDatum = (diamondFacilityDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_FACILITY_DATUM,
      payload: diamondFacilityDatum,
    });
  };
};

export const initDiamondFacilitySearchData = (diamondFacilitySearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DIAMOND_FACILITY_SEARCHDATA,
      payload: diamondFacilitySearchData,
    });
  };
};

export const updateDiamondFacilitySearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_FACILITY_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateDiamondFacilityErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_FACILITY_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setDiamondDeviceDatum = (diamondDeviceDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_DEVICE_DATUM,
      payload: diamondDeviceDatum,
    });
  };
};

export const initDiamondDeviceSearchData = (diamondDeviceSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DIAMOND_DEVICE_SEARCHDATA,
      payload: diamondDeviceSearchData,
    });
  };
};

export const updateDiamondDeviceSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_DEVICE_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateDiamondDeviceErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_DEVICE_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const setMasterCooperationSystemDatum = (masterCooperationSystemDatum) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MASTER_COOPERATION_SYSTEM_DATUM,
      payload: masterCooperationSystemDatum,
    });
  };
};

export const initMasterCooperationSystemSearchData = (masterCooperationSystemSearchData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_MASTER_COOPERATION_SYSTEM_SEARCHDATA,
      payload: masterCooperationSystemSearchData,
    });
  };
};

export const updateMasterCooperationSystemSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MASTER_COOPERATION_SYSTEM_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};

export const updateMasterCooperationSystemErrorSearchData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_SEARCHDATA,
      payload: { [key]: value },
    });
  };
};
