import CmtImage from '@coremat/CmtImage';
import { Box } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

const Logo = ({ color, ...props }) => {
  const logoUrl = color === 'white' ? '/images/auth/robohub.png' : '/images/auth/robohub.png';

  return (
    <Box className="pointer" {...props}>
      <NavLink to="/master-organizations">
        <CmtImage src={logoUrl} alt="logo" style={{ height: 40, display: 'block' }} />
      </NavLink>
    </Box>
  );
};

export default Logo;
