import CmtAvatar from '@coremat/CmtAvatar';
import CmtCard from '@coremat/CmtCard';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtGridView from '@coremat/CmtGridView';
import AppsIcon from '@mui/icons-material/Apps';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EmailIcon from '@mui/icons-material/Email';
import { Box, IconButton, Popover, Tooltip, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useClasses from 'theme/useClasses';

const styles = (theme) => ({
  cardRoot: {
    '& .Cmt-header-root': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  typography: {
    padding: theme.spacing(2),
  },
  iconRoot: {
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
});

const actions = [
  {
    label: 'More Detail',
  },
  {
    label: 'Close',
  },
];

const applications = [
  {
    name: 'Email',
    icon: <EmailIcon style={{ color: '#0795F4' }} />,
    bgColor: '#CDEAFD',
    path: '/apps/mail',
  },
  {
    name: 'Task',
    icon: <CheckCircleIcon style={{ color: '#FF8C00' }} />,
    bgColor: '#FFE8CC',
    path: '/apps/to-do',
  },
  {
    name: 'Contacts',
    icon: <ContactMailIcon style={{ color: '#8DCD03' }} />,
    bgColor: '#E8F5CD',
    path: '/apps/contact',
  },
  {
    name: 'Chating',
    icon: <ChatIcon style={{ color: '#6200EE' }} />,
    bgColor: '#E0CCFC',
    path: '/apps/chat',
  },
];

const appItem = (item, index, onClick) => {
  return (
    <Box key={index} py={2} className="pointer" display="flex" flexDirection="column" alignItems="center" onClick={() => onClick(item.path)}>
      <CmtAvatar style={{ backgroundColor: item.bgColor }} size={62}>
        {item.icon}
      </CmtAvatar>
      <Box mt={5} fontSize={16} fontWeight="bold" color="#666666">
        {item.name}
      </Box>
    </Box>
  );
};

const AppsMenu = () => {
  const theme = useTheme();
  const classes = useClasses(styles(theme));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickApp = (path) => {
    navigate(path);
    onClosePopOver();
  };

  return (
    <div>
      <Tooltip title="Applications">
        <IconButton onClick={onOpenPopOver} className={clsx(classes.iconRoot, 'Cmt-appIcon')} size="large">
          <AppsIcon />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CmtCard className={classes.cardRoot}>
          <CmtCardHeader
            title="Applications"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          />
          <CmtCardContent>
            {applications.length > 0 ? (
              <CmtGridView itemPadding={24} column={2} data={applications} renderRow={(item, index) => appItem(item, index, onClickApp)} />
            ) : (
              <Typography variant="body2">No applications found</Typography>
            )}
          </CmtCardContent>
        </CmtCard>
      </Popover>
    </div>
  );
};

export default AppsMenu;
