import { DRAWER_BREAK_POINT, HEADER_TYPE, LAYOUT_STYLES, LAYOUT_TYPES, SIDEBAR_TYPE, SIDEBAR_WIDTH, THEME_TYPES } from 'constants/ThemeOptions';
import defaultTheme from 'theme/defaultTheme';

const defaultContext = {
  theme: defaultTheme,
  defaultLng: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  layout: LAYOUT_TYPES.VERTICAL_DEFAULT,
  layoutType: LAYOUT_STYLES.FULL_WIDTH,
  themeType: THEME_TYPES.LIGHT,
  drawerBreakPoint: DRAWER_BREAK_POINT.MD,
  headerType: HEADER_TYPE.FIXED,
  sidebarType: SIDEBAR_TYPE.MINI,
  isSidebarFixed: false,
  sidebarWidth: SIDEBAR_WIDTH.WIDE,
  showFooter: false,
};

export default defaultContext;
