import CmtImage from '@coremat/CmtImage';
import SidebarThemeContext from '@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import useClasses from 'theme/useClasses';

const styles = (theme, sidebarTheme) => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: `solid 1px ${sidebarTheme.borderColor}`,
    '.Cmt-miniLayout &': {
      padding: '20px 16px 12px 16px',
      borderBottom: 'initial',
    },
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    // miniの時にhoverで開くのを無効化
    // '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //   height: 0,
    //   paddingTop: 0,
    //   opacity: 0,
    //   transition: 'all 0.3s ease',
    // },
    '.Cmt-miniLayout &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
  logo: {
    width: 240,
    '.Cmt-miniLayout &': {
      display: 'none',
    },
  },
  logo_r: {
    display: 'none',
    '.Cmt-miniLayout &': {
      display: 'initial',
    },
  },
});

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const theme = useTheme();
  const classes = useClasses(styles(theme, sidebarTheme));
  const user = useSelector(({ auth }) => auth.authUser);

  return (
    <Box className={classes.root}>
      <CmtImage className={classes.logo_r} src={'/images/auth/robohub_r.png'} alt="「RoboHUB」のロゴ画像" />
      <CmtImage className={classes.logo} src={'/images/auth/robohub.png'} alt="「RoboHUB」のロゴ画像" />
      <Box className={classes.userInfo}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box mr={2}>
            <Typography className={classes.userTitle} component="h3">
              {user && user.name}
            </Typography>
            <Typography className={classes.userSubTitle}>{user && user.mail}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarHeader;
