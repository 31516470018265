import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import useClasses from 'theme/useClasses';

const styles = () => ({
  headerNav: {
    width: '100%',
    minHeight: 46,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
});

const CmtHeaderNav = ({ children, ...rest }) => {
  const classes = useClasses(styles());

  return (
    <Box className={clsx(classes.headerNav, 'Cmt-header-nav')} {...rest}>
      <div className="Cmt-container">{children}</div>
    </Box>
  );
};

export default CmtHeaderNav;
