import { Fonts } from '../constants/ThemeOptions';

const defaultTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 5,
  direction: 'ltr',
  palette: {
    mode: 'light',
    common: {
      black: '#000000',
      white: '#ffffff',
      dark: '#020202',
    },
    primary: {
      light: '#868dcd',
      main: '#3541AC',
      dark: '#202767',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#cbcff7',
      main: '#a8aff1',
      dark: '#656991',
      contrastText: '#ffffff',
    },
    // CUSTOM theme
    accent: {
      light: '#e866b4',
      main: '#d80082',
      dark: '#82004e',
      contrastText: '#ffffff',
    },
    body: {
      main: '#f4f4f7',
    },
    bodyText: {
      light: '#898989',
      main: '#3b3b3b',
      dark: '#232323',
      contrastText: '#ffffff',
    },
    lineIcon: {
      light: '#b8b8b8',
      main: '#888888',
      dark: '#525252',
      contrastText: '#ffffff',
    },
    lineIcon2: {
      light: '#dadada',
      main: '#c2c2c2',
      dark: '#747474',
    },
    info: {
      light: '#cbcff7',
      main: '#a8aff1',
      dark: '#656991',
    },
    success: {
      light: '#66ce93',
      main: '#00ae4b',
      dark: '#00682d',
    },
    warning: {
      light: '#FFDE99',
      main: '#FF8C00',
      dark: '#D36F1A',
    },
    error: {
      light: '#ee8282',
      main: '#e22e2e',
      dark: '#881c1c',
    },
    tableHeader: {
      light: '#868dcd',
      main: '#3541AC',
      dark: '#202767',
      textColor: '#ffffff',
    },
    tableFooter: {
      main: '#C2C2C2',
    },
    tableBorderColor: {
      main: '#C2C2C2',
    },
    sidebar: {
      bgColor: '#ffffff',
      textColor: '#3b3b3b',
      textDarkColor: '#3b3b3b',
      textActiveColor: '#3541AC',
      navHoverBgColor: 'rgb(229, 229, 229)',
      navActiveBgColor: 'rgb(239, 229, 253)',
      borderColor: 'rgba(33, 33, 33, 0.08)',
    },
    horizontalNav: {
      navigationColor: 'rgba(255, 255, 255, 0.74)',
      navigationActiveColor: 'rgba(255, 255, 255, 1)',
      textColor: 'rgba(0, 0, 0, 0.6)',
      textDarkColor: 'rgba(0, 0, 0, 0.87)',
      textActiveColor: '#6200EE',
      menuHoverBgColor: 'rgb(229, 229, 229)',
      menuActiveBgColor: 'rgb(239, 229, 253)',
    },
    background: {
      paper: '#FFFFFF',
      default: '#f4f4f7',
    },
    text: {
      primary: '#3b3b3b',
      secondary: '#3b3b3b',
      disabled: '#3b3b3b',
      hint: '#3b3b3b',
      white: '#ffffff',
    },
    btn: {
      hover: 'rgba(0, 0, 0, 0.08)',
    },
    lightBtn: {
      bgColor: '#f5f5f5',
      textColor: 'rgba(0, 0, 0, 0.38)',
    },
    borderColor: {
      main: 'rgba(0, 0, 0, 0.06)',
      dark: 'rgba(0, 0, 0, 0.12)',
    },
    popupColor: {
      main: '#ffffff',
    },
  },
  status: {
    danger: 'orange',
  },
  // eslint-disable-next-line no-sparse-arrays
  shadows: [
    'none', // 0
    '0px 3px 6px #00000029;', // 1
    '0px 3px 6px #00000029;', // 2
    '0px 3px 6px #00000029;', // 3
    '0px 3px 6px #00000029;', // 4
    '0px 3px 6px #00000029;', // 5
    '0px 3px 6px #00000029;', // 6
    '0px 3px 6px #00000029;', // 7
    '0px 3px 6px #00000029;', // 8
    '0px 3px 6px #00000029;', // 9
    '0px 3px 6px #00000029;', // 10
    '0px 3px 6px #00000029;', // 11
    '0px 3px 6px #00000029;', // 12
    '0px 3px 6px #00000029;', // 13
    '0px 3px 6px #00000029;', // 14
    '0px 3px 6px #00000029;', // 15
    '0px 3px 6px #00000029;', // 16
    '0px 3px 6px #00000029;', // 17
    '0px 3px 6px #00000029;', // 18
    '0px 3px 6px #00000029;', // 19
    '0px 3px 6px #00000029;', // 20
    '0px 3px 6px #00000029;', // 21
    '0px 3px 6px #00000029;', // 22
    '0px 3px 6px #00000029;', // 23
    '0px 3px 6px #00000029;', // 24
  ],
  typography: {
    fontFamily: Fonts.PRIMARY,
    fontWeightExtraLight: 200,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    fontWeightExtraBold: 900,
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 20,
        fontWeight: 900,
      },
      h2: {
        fontSize: 18,
        fontWeight: 'bold',
      },
      h3: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      h4: {
        fontSize: 16,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: 14,
        fontWeight: 400,
      },
      h6: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.5,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.15,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 0.1,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
        letterSpacing: 0.5,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: 0.25,
      },
    },
    MuiFormHelperText: {
      contained: {
        color: '#d80082',
      },
    },
    MuiButton: {
      root: {
        fontWeight: 'bold',
        letterSpacing: 1.25,
        fontSize: 13,
      },
    },
    MuiToggleButton: {
      root: {
        borderRadius: 4,
      },
    },
    MuiCardLg: {
      root: {
        borderRadius: 10,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 4,
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)',
      },
    },
    MuiTab: {
      textColorPrimary: {
        color: '#3b3b3b',
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: '#FFFFFF',
      },
    },
  },
};
export default defaultTheme;
