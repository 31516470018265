import React from 'react';
import clsx from 'clsx';
import { CheckCircleRounded, CloseRounded, ErrorRounded, InfoRounded, WarningRounded } from '@mui/icons-material';
import useClasses from 'theme/useClasses';
import defaultTheme from 'theme/defaultTheme';
import { IconButton, Slide } from '@mui/material';

const styles = () => ({
  root: {
    position: 'fixed',
    top: '74px',
    left: 10,
    right: 10,
    maxWidth: 1008,
    margin: 'env(safe-area-inset-top) auto 0',
    zIndex: 9999,
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: '100%',
    color: defaultTheme.palette.common.white,
    borderRadius: 12,
  },
  error: { background: defaultTheme.palette.error.main },
  info: { background: defaultTheme.palette.secondary.main },
  success: { background: defaultTheme.palette.success.main },
  warning: { background: defaultTheme.palette.warning.main },
  // icon
  iconWrapper: {
    flex: '0 0 40px',
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSVG: {
    display: 'block',
  },
  // message
  message: {
    flex: '1 0',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 0',
    paddingLeft: 5,
  },
  messageText: {
    margin: 0,
    fontSize: '1rem',
  },
  closeBtnIcon: {
    color: defaultTheme.palette.common.white,
  },
});

const Snackbar = (props) => {
  const { open, onClose, message, severty, className, autoHideDuration, ...other } = props;
  const classes = useClasses(styles());

  const icon = {
    error: <ErrorRounded className={classes.iconSVG} />,
    info: <InfoRounded className={classes.iconSVG} />,
    success: <CheckCircleRounded className={classes.iconSVG} />,
    warning: <WarningRounded className={classes.iconSVG} />,
  };

  return (
    <Slide in={open} timeout={200}>
      <div className={clsx(classes.root, className)}>
        <div {...other} className={clsx(classes.box, classes[severty], open && classes.open)}>
          <div className={classes.iconWrapper}>
            <div className={classes.icon}>{icon[severty]}</div>
          </div>
          <div className={classes.message}>
            <p className={classes.messageText}>{message}</p>
          </div>
          <IconButton onClick={onClose}>
            <CloseRounded className={classes.closeBtnIcon} />
          </IconButton>
        </div>
      </div>
    </Slide>
  );
};

export default Snackbar;
