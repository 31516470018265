import {
  UPDATE_ORGANIZATION_DATUM,
  INIT_ORGANIZATION_SEARCHDATA,
  UPDATE_ORGANIZATION_SEARCHDATA,
  UPDATE_ORGANIZATION_ERROR_SEARCHDATA,
  UPDATE_DEVICE_MAKER_DATUM,
  INIT_DEVICE_MAKER_SEARCHDATA,
  UPDATE_DEVICE_MAKER_SEARCHDATA,
  UPDATE_DEVICE_MAKER_ERROR_SEARCHDATA,
  UPDATE_SITE_DATUM,
  INIT_SITE_SEARCHDATA,
  UPDATE_SITE_SEARCHDATA,
  UPDATE_SITE_ERROR_SEARCHDATA,
  UPDATE_FACILITY_DATUM,
  INIT_FACILITY_SEARCHDATA,
  UPDATE_FACILITY_SEARCHDATA,
  UPDATE_FACILITY_ERROR_SEARCHDATA,
  UPDATE_DEVICE_DATUM,
  INIT_DEVICE_SEARCHDATA,
  UPDATE_DEVICE_SEARCHDATA,
  UPDATE_DEVICE_ERROR_SEARCHDATA,
  UPDATE_CHILD_DEVICE_DATUM,
  INIT_CHILD_DEVICE_SEARCHDATA,
  UPDATE_CHILD_DEVICE_SEARCHDATA,
  UPDATE_CHILD_DEVICE_ERROR_SEARCHDATA,
  UPDATE_ACCOUNT_DATUM,
  INIT_ACCOUNT_SEARCHDATA,
  UPDATE_ACCOUNT_SEARCHDATA,
  UPDATE_ACCOUNT_ERROR_SEARCHDATA,
  UPDATE_COOPERATION_SYSTEM_DATUM,
  INIT_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_COOPERATION_SYSTEM_ERROR_SEARCHDATA,
  UPDATE_DIAMOND_CONNECTION_DATUM,
  INIT_DIAMOND_CONNECTION_SEARCHDATA,
  UPDATE_DIAMOND_CONNECTION_SEARCHDATA,
  UPDATE_DIAMOND_CONNECTION_ERROR_SEARCHDATA,
  UPDATE_DIAMOND_FACILITY_DATUM,
  INIT_DIAMOND_FACILITY_SEARCHDATA,
  UPDATE_DIAMOND_FACILITY_SEARCHDATA,
  UPDATE_DIAMOND_FACILITY_ERROR_SEARCHDATA,
  UPDATE_DIAMOND_DEVICE_DATUM,
  INIT_DIAMOND_DEVICE_SEARCHDATA,
  UPDATE_DIAMOND_DEVICE_SEARCHDATA,
  UPDATE_DIAMOND_DEVICE_ERROR_SEARCHDATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_DATUM,
  INIT_MASTER_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_SEARCHDATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_SEARCHDATA,
} from 'constants/ActionTypes';

const INIT_STATE = {
  organizationDatum: null,
  organizationSearchData: {},
  organizationErrorSearchData: {},
  deviceMakerDatum: null,
  deviceMakerSearchData: {},
  deviceMakerErrorSearchData: {},
  siteDatum: null,
  siteSearchData: {},
  siteErrorSearchData: {},
  facilityDatum: null,
  facilitySearchData: {},
  facilityErrorSearchData: {},
  sensorMasterDatum: null,
  sensorMasterSearchData: {},
  sensorMasterErrorSearchData: {},
  childDeviceDatum: null,
  childDeviceSearchData: {},
  childDeviceErrorSearchData: {},
  accountDatum: null,
  accountSearchData: {},
  accountErrorSearchData: {},
  cooperationSystemDatum: null,
  cooperationSystemSearchData: {},
  cooperationSystemErrorSearchData: {},
  diamondConnectionDatum: null,
  diamondConnectionSearchData: {},
  diamondConnectionErrorSearchData: {},
  diamondFacilityDatum: null,
  diamondFacilitySearchData: {},
  diamondFacilityErrorSearchData: {},
  diamondDeviceDatum: null,
  diamondDeviceSearchData: {},
  diamondDeviceErrorSearchData: {},
  masterCooperationSystemDatum: null,
  masterCooperationSystemSearchData: {},
  masterCooperationSystemErrorSearchData: {},
  // siteDatum_tgc: null,
  // siteSearchData_tgc: {},
  // siteErrorSearchData_tgc: {},
  // employeeDatum_tgc: null,
  // employeeSearchData_tgc: {},
  // employeeErrorSearchData_tgc: {},
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_ORGANIZATION_DATUM: {
      return {
        ...state,
        organizationDatum: action.payload,
      };
    }
    case INIT_ORGANIZATION_SEARCHDATA: {
      return {
        ...state,
        organizationSearchData: action.payload,
        organizationErrorSearchData: {},
      };
    }
    case UPDATE_ORGANIZATION_SEARCHDATA: {
      return {
        ...state,
        organizationSearchData: { ...state.organizationSearchData, ...action.payload },
      };
    }
    case UPDATE_ORGANIZATION_ERROR_SEARCHDATA: {
      return {
        ...state,
        organizationErrorSearchData: { ...state.organizationErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_DEVICE_MAKER_DATUM: {
      return {
        ...state,
        deviceMakerDatum: action.payload,
      };
    }
    case INIT_DEVICE_MAKER_SEARCHDATA: {
      return {
        ...state,
        deviceMakerSearchData: action.payload,
        deviceMakerErrorSearchData: {},
      };
    }
    case UPDATE_DEVICE_MAKER_SEARCHDATA: {
      return {
        ...state,
        deviceMakerSearchData: { ...state.deviceMakerSearchData, ...action.payload },
      };
    }
    case UPDATE_DEVICE_MAKER_ERROR_SEARCHDATA: {
      return {
        ...state,
        deviceMakerErrorSearchData: { ...state.deviceMakerErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_SITE_DATUM: {
      return {
        ...state,
        siteDatum: action.payload,
      };
    }
    case INIT_SITE_SEARCHDATA: {
      return {
        ...state,
        siteSearchData: action.payload,
        siteErrorSearchData: {},
      };
    }
    case UPDATE_SITE_SEARCHDATA: {
      return {
        ...state,
        siteSearchData: { ...state.siteSearchData, ...action.payload },
      };
    }
    case UPDATE_SITE_ERROR_SEARCHDATA: {
      return {
        ...state,
        siteErrorSearchData: { ...state.siteErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_FACILITY_DATUM: {
      return {
        ...state,
        facilityDatum: action.payload,
      };
    }
    case INIT_FACILITY_SEARCHDATA: {
      return {
        ...state,
        facilitySearchData: action.payload,
        facilityErrorSearchData: {},
      };
    }
    case UPDATE_FACILITY_SEARCHDATA: {
      return {
        ...state,
        facilitySearchData: { ...state.facilitySearchData, ...action.payload },
      };
    }
    case UPDATE_FACILITY_ERROR_SEARCHDATA: {
      return {
        ...state,
        facilityErrorSearchData: { ...state.facilityErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_DEVICE_DATUM: {
      return {
        ...state,
        sensorMasterDatum: action.payload,
      };
    }
    case INIT_DEVICE_SEARCHDATA: {
      return {
        ...state,
        sensorMasterSearchData: action.payload,
        sensorMasterErrorSearchData: {},
      };
    }
    case UPDATE_DEVICE_SEARCHDATA: {
      return {
        ...state,
        sensorMasterSearchData: { ...state.sensorMasterSearchData, ...action.payload },
      };
    }
    case UPDATE_DEVICE_ERROR_SEARCHDATA: {
      return {
        ...state,
        sensorMasterErrorSearchData: { ...state.sensorMasterErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_CHILD_DEVICE_DATUM: {
      return {
        ...state,
        childDeviceDatum: action.payload,
      };
    }
    case INIT_CHILD_DEVICE_SEARCHDATA: {
      return {
        ...state,
        childDeviceSearchData: action.payload,
        childDeviceErrorSearchData: {},
      };
    }
    case UPDATE_CHILD_DEVICE_SEARCHDATA: {
      return {
        ...state,
        childDeviceSearchData: { ...state.childDeviceSearchData, ...action.payload },
      };
    }
    case UPDATE_CHILD_DEVICE_ERROR_SEARCHDATA: {
      return {
        ...state,
        childDeviceErrorSearchData: { ...state.childDeviceErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_ACCOUNT_DATUM: {
      return {
        ...state,
        accountDatum: action.payload,
      };
    }
    case INIT_ACCOUNT_SEARCHDATA: {
      return {
        ...state,
        accountSearchData: action.payload,
        accountErrorSearchData: {},
      };
    }
    case UPDATE_ACCOUNT_SEARCHDATA: {
      return {
        ...state,
        accountSearchData: { ...state.accountSearchData, ...action.payload },
      };
    }
    case UPDATE_ACCOUNT_ERROR_SEARCHDATA: {
      return {
        ...state,
        accountErrorSearchData: { ...state.accountErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_COOPERATION_SYSTEM_DATUM: {
      return {
        ...state,
        cooperationSystemDatum: action.payload,
      };
    }
    case INIT_COOPERATION_SYSTEM_SEARCHDATA: {
      return {
        ...state,
        cooperationSystemSearchData: action.payload,
        cooperationSystemErrorSearchData: {},
      };
    }
    case UPDATE_COOPERATION_SYSTEM_SEARCHDATA: {
      return {
        ...state,
        cooperationSystemSearchData: { ...state.cooperationSystemSearchData, ...action.payload },
      };
    }
    case UPDATE_COOPERATION_SYSTEM_ERROR_SEARCHDATA: {
      return {
        ...state,
        cooperationSystemErrorSearchData: { ...state.cooperationSystemErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_CONNECTION_DATUM: {
      return {
        ...state,
        diamondConnectionDatum: action.payload,
      };
    }
    case INIT_DIAMOND_CONNECTION_SEARCHDATA: {
      return {
        ...state,
        diamondConnectionSearchData: action.payload,
        diamondConnectionErrorSearchData: {},
      };
    }
    case UPDATE_DIAMOND_CONNECTION_SEARCHDATA: {
      return {
        ...state,
        diamondConnectionSearchData: { ...state.diamondConnectionSearchData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_CONNECTION_ERROR_SEARCHDATA: {
      return {
        ...state,
        diamondConnectionErrorSearchData: { ...state.diamondConnectionErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_FACILITY_DATUM: {
      return {
        ...state,
        diamondFacilityDatum: action.payload,
      };
    }
    case INIT_DIAMOND_FACILITY_SEARCHDATA: {
      return {
        ...state,
        diamondFacilitySearchData: action.payload,
        diamondFacilityErrorSearchData: {},
      };
    }
    case UPDATE_DIAMOND_FACILITY_SEARCHDATA: {
      return {
        ...state,
        diamondFacilitySearchData: { ...state.diamondFacilitySearchData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_FACILITY_ERROR_SEARCHDATA: {
      return {
        ...state,
        diamondFacilityErrorSearchData: { ...state.diamondFacilityErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_DEVICE_DATUM: {
      return {
        ...state,
        diamondDeviceDatum: action.payload,
      };
    }
    case INIT_DIAMOND_DEVICE_SEARCHDATA: {
      return {
        ...state,
        diamondDeviceSearchData: action.payload,
        diamondDeviceErrorSearchData: {},
      };
    }
    case UPDATE_DIAMOND_DEVICE_SEARCHDATA: {
      return {
        ...state,
        diamondDeviceSearchData: { ...state.diamondDeviceSearchData, ...action.payload },
      };
    }
    case UPDATE_DIAMOND_DEVICE_ERROR_SEARCHDATA: {
      return {
        ...state,
        diamondDeviceErrorSearchData: { ...state.diamondDeviceErrorSearchData, ...action.payload },
      };
    }
    case UPDATE_MASTER_COOPERATION_SYSTEM_DATUM: {
      return {
        ...state,
        masterCooperationSystemDatum: action.payload,
      };
    }
    case INIT_MASTER_COOPERATION_SYSTEM_SEARCHDATA: {
      return {
        ...state,
        masterCooperationSystemSearchData: action.payload,
        masterCooperationSystemErrorSearchData: {},
      };
    }
    case UPDATE_MASTER_COOPERATION_SYSTEM_SEARCHDATA: {
      return {
        ...state,
        masterCooperationSystemSearchData: { ...state.masterCooperationSystemSearchData, ...action.payload },
      };
    }
    case UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_SEARCHDATA: {
      return {
        ...state,
        masterCooperationSystemErrorSearchData: { ...state.masterCooperationSystemErrorSearchData, ...action.payload },
      };
    }
    default:
      return state;
  }
};

export default reducer;
