import { OutlinedInput } from '@mui/material';

// 行ごとに配列グループにして格納
export const editObj = [
  [
    {
      name: 'rowKey',
      label: '設備キー(rowKey)',
      editable: true,
      init: '',
      required: false,
      type: 'text',
      component: OutlinedInput,
      tooltiptext: 'このレコードの一意のキー。変更はできません',
      disabledComponent: true,
      sx: { width: '400px' },
    },
  ],
  [
    { name: 'name', label: '三菱設備名称', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '', sx: { width: '400px' } },
    { name: 'kana', label: '三菱設備名称カナ', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '', sx: { width: '400px' } },
  ],
  [{ name: 'available_floors', label: '有効階', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '', sx: { width: '400px' } }],
  [{ name: 'bank_no', label: 'バンク番号', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '' }],
  [
    { name: 'x1', label: 'x1', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '' },
    { name: 'x2', label: 'x2', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '' },
  ],
  [
    { name: 'y1', label: 'y1', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '' },
    { name: 'y2', label: 'y2', editable: true, init: '', required: true, component: OutlinedInput, tooltiptext: '' },
  ],
];

export const timeoutObj = [
  {
    no: 1,
    name: 'elvDcCallElevatorResult',
    tooltipText: `Ville-feuilleに「エレベータ呼び出し要求」を送信してから、Ville-feuilleから「割当かご通知」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は30秒、リトライ回数は3回です。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '30',
    limitTryCount: '3',
  },
  {
    no: 2,
    name: 'elvDcBoarding',
    tooltipText: `Ville-feuilleから「割当かご通知」を受信してから、Ville-feuilleから「乗車要求」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は120秒、リトライ回数は0回です。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '120',
    limitTryCount: '0',
  },
  {
    no: 3,
    name: 'elvDcBrdgPassAccept',
    tooltipText: `連携システムに「乗り込み要求」を送信してから、連携システムから「乗り込み要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '10',
    limitTryCount: '3',
  },
  {
    no: 4,
    name: 'elvDcBrdgPassComplete',
    tooltipText: `連携システムに「乗り込み要求」を送信してから、連携システムから「乗り込み完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は150秒、リトライ回数は設定できません。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '150',
    limitTryCount: '0',
  },
  {
    no: 5,
    name: 'elvDcAlighting',
    tooltipText: `Ville-feuilleに「エレベーターの使用状況」を送信してから、Ville-feuilleから「降車要求」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は300秒、リトライ回数は設定できません。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '300',
    limitTryCount: '0',
  },
  {
    no: 6,
    name: 'elvDcAltgPassAccept',
    tooltipText: `連携システムに「降車要求」を送信してから、連携システムから「降車要求応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は10秒、リトライ回数は3回です。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '10',
    limitTryCount: '3',
  },
  {
    no: 7,
    name: 'elvDcAltgPassComplete',
    tooltipText: `連携システムに「降車要求」を送信してから、連携システムから「降車完了応答」を受信するまでのタイムアウト時間およびリトライ回数を設定します。
    デフォルトのタイムアウト時間は150秒、リトライ回数は設定できません。`,
    minTime: '2',
    maxTime: '999999',
    timeoutDelay: '150',
    limitTryCount: '0',
  },
];
