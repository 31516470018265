import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import useClasses from 'theme/useClasses';

const styles = () => ({
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgress: {
    marginBottom: '12px',
  },
});

const PageLoader = ({ message = '' }) => {
  const classes = useClasses(styles());

  return (
    <Box className={classes.circularProgressRoot}>
      <CircularProgress className={classes.circularProgress} />
      <span style={{ fontSize: '14px' }}>{message}</span>
    </Box>
  );
};

export default PageLoader;
