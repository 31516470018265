import { useTheme } from '@emotion/react';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import ErrorStackParser from 'error-stack-parser';
import React, { Fragment } from 'react';
import useClasses from 'theme/useClasses';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: '10px 6px 8px hsla(0,0%,45.9%,.8)',
  },
  searchRoot: {
    position: 'relative',
    width: 1000,
    [theme.breakpoints.up('md')]: {
      width: 1000,
    },
    '& .searchBtn': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      boxSizing: 'border-box',
      padding: '5px 50px 5px 15px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
});

const ErrorBoundaryComponent = (props) => {
  const { error } = props;
  const theme = useTheme();
  const classes = useClasses(styles(theme));

  const stackFrames = error ? ErrorStackParser.parse(error) : [];

  return (
    <Box className={classes.root}>
      <Box fontSize={{ xs: 100, sm: 160 }} className={classes.errorNumber}>
        error has occurred
      </Box>
      <Box fontSize={{ xs: 16, sm: 24 }} mb={8} color="grey.500">
        クライアントアプリケーションにてエラーが発生しました
      </Box>
      <Box className={classes.searchRoot}>
        {error?.toString() || ''}
        <br />
        {stackFrames.map((stack, index) => (
          <Fragment key={index}>
            &nbsp;&nbsp;&nbsp;&nbsp;{stack.source}
            <br />
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default ErrorBoundaryComponent;
