export const ENTRY_SUCCESS_TEXT = 'データ登録/更新を行いました';
export const ENTRY_FAILED_TEXT = 'データ登録/更新に失敗しました';

// debounceTime
export const DEBOUNCE_TIME = 0;
export const API_DEBOUNCE_TIME = 200;
export const API_TEXT_DEBOUNCE_TIME = 1500;

// 電話番号フォーマッタ（google-libphonenumber）の国コード
export const TEL_JP = 'JP';

// 大成建設ユーザ
export const TAISEI = '1';
// 施設ユーザ
export const CUSTOMER = '2';
// ロボットメーカユーザ
export const FMUSER = '3';
// オーナーレベルユーザ
export const OWNER = '1';
// 参照権限ユーザ
export const READONLY = '2';

// DatetimeFormat
export const DATETIME_FORMAT = 'yyyy/MM/dd HH:mm:ss +09:00';

// 新規登録画面切り替え用
export const ENTRY = 0;
export const SECOND_ENTRY = 1;
export const SUCCESS = 2;
export const FAIL = 3;
export const ON_LOAD = 99;
export const POST = 22;

// selectMenu用
export const SUBTITLE = 1;
export const OPTION = 0;

// delete_flg
export const AVAILABLE = 0;
export const DELETED = 1;

// simulator_flg
export const IS_SIMULATOR = '1';
export const IS_NOT_SIMULATOR = '2';

// running_flg
export const RUNNING = '1';
export const NOT_RUNNING = '2';

// role
export const SUPER_USER = '11'; // RoboHUB開発者
export const EMPLOYEE = '12'; // 社内関係者
export const SUPPORT = '13'; // サポートセンター
export const FAC_OWNER = '21'; // 施設オーナー
export const FAC_ADMIN = '22'; // ロボット施設管理者
export const FAC_USER = '23'; // 施設内ユーザ
export const ROBOT_ADMIN = '31'; // フリートマネージャー/ロボットメーカー担当者

// アカウントの編集権限用
export const NOT_ALLOWED = 0; // 不可
export const ALLOW_SELF = 1; // 自分のみOK
export const ALLOW_ALL = 99; // 自分以外もOK

// 権限ごとのログイン後ランディングページ
export const landingPage = {
  [SUPER_USER]: '/master-organizations',
  [EMPLOYEE]: '/master-organizations',
  [SUPPORT]: '/master-organizations',
  [FAC_OWNER]: '/summary-monitoring-devices',
  [FAC_ADMIN]: '/summary-monitoring-devices',
  [FAC_USER]: '/summary-monitoring-devices',
  [ROBOT_ADMIN]: '/summary-monitoring-devices',
};

// 環境
export const PROD = 'PROD';
export const STG = 'STG';
export const DEV = 'DEV';
export const LOCAL = 'LOCAL';

// logの色分け用
export const WARNING_LOG = 2;
export const ERROR_LOG = 3;
export const PUB_FROM_CS = 4;
export const PUB_FROM_PLC = 5;
export const PUB_FROM_IM = 6;

// 出力ログ種類
export const ALL_LOG = 1;
export const MAILSEND_LOG = 2;

// パスワード強度
export const passStrText = { 0: '最弱', 1: 'やや弱', 2: '弱', 3: '標準', 4: '強' };

// 正規表現 Email
export const email_regex = /^([a-zA-Z0-9])+([a-zA-Z0-9+._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9._-]+)+$/;

// 正規表現 カナのみ
export const kana_regex = /^[ァ-ヶー　]+$/;

// 正規表現 パスワード
export const password_regex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/;

// 正規表現 半角英数字のみ
export const numAb_regex = /^[0-9a-zA-Z]*$/;

// 正規表現 半角英数字記号のみ
export const numAbSyb_regex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;

// 正規表現 半角英数字スペースのみ
export const numAbSpace_regex = /^[0-9a-zA-Z ]*$/;

// 正規表現 電話番号
export const tel_regex = /^[0-9-]+$/;

// 正規表現 顧客管理表電話番号
export const telMatter_regex = /^[0-9０-９-―ー‐－]+$/;

// 正規表現 数字小数点のみ
export const demical_regex = /^[0-9.]+$/;

// 正規表現 数字小数点のみ
export const demical_regex2 = /^[0-9]+\.[0-9]+$/;

// 正規表現 数字のみ
export const number_regex = /^[0-9]+$/;

// 正規表現 郵便番号
// export const postal_regex = /(^[0-9０-９]{7}$)|(^[0-9０-９]{3}(-|―|ー|‐|－)[0-9０-９]{4}$)/;
export const postal_regex = /^[0-9]{7}$/;

// 正規表現 IPアドレス
export const ipAddress_regex = /^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

// 正規表現 半角数字とコンマ
export const number_comma_regex = /^(?:\d+,)*\d+$/;

// 正規表現 半角英数字とコンマ
export const abnumber_comma_regex = /^(?:[0-9a-zA-Z]+,)*[0-9a-zA-Z]+$/;
