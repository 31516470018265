import { ALL_LOG, EMPLOYEE, FAC_ADMIN, FAC_OWNER, FAC_USER, MAILSEND_LOG, OPTION, ROBOT_ADMIN, SUBTITLE, SUPER_USER, SUPPORT } from './CommonConstants';

export const dataReceiveChkMenus = [
  { name: '未', value: 0 },
  { name: '済', value: 1 },
];

export const startTimeMenus = [
  { name: '0時', value: 0 },
  { name: '1時', value: 1 },
  { name: '2時', value: 2 },
  { name: '3時', value: 3 },
  { name: '4時', value: 4 },
  { name: '5時', value: 5 },
  { name: '6時', value: 6 },
  { name: '7時', value: 7 },
  { name: '8時', value: 8 },
  { name: '9時', value: 9 },
  { name: '10時', value: 10 },
  { name: '11時', value: 11 },
  { name: '12時', value: 12 },
  { name: '13時', value: 13 },
  { name: '14時', value: 14 },
  { name: '15時', value: 15 },
  { name: '16時', value: 16 },
  { name: '17時', value: 17 },
  { name: '18時', value: 18 },
  { name: '19時', value: 19 },
  { name: '20時', value: 20 },
  { name: '21時', value: 21 },
  { name: '22時', value: 22 },
  { name: '23時', value: 23 },
];

export const startDayMenus = [
  { name: '日', value: 1 },
  { name: '月', value: 2 },
  { name: '火', value: 3 },
  { name: '水', value: 4 },
  { name: '木', value: 5 },
  { name: '金', value: 6 },
  { name: '土', value: 7 },
];

export const startDateMenus = [
  { name: '', value: 0 },
  { name: '1日', value: 1 },
  { name: '2日', value: 2 },
  { name: '3日', value: 3 },
  { name: '4日', value: 4 },
  { name: '5日', value: 5 },
  { name: '6日', value: 6 },
  { name: '7日', value: 7 },
  { name: '8日', value: 8 },
  { name: '9日', value: 9 },
  { name: '10日', value: 10 },
  { name: '11日', value: 11 },
  { name: '12日', value: 12 },
  { name: '13日', value: 13 },
  { name: '14日', value: 14 },
  { name: '15日', value: 15 },
  { name: '16日', value: 16 },
  { name: '17日', value: 17 },
  { name: '18日', value: 18 },
  { name: '19日', value: 19 },
  { name: '20日', value: 20 },
  { name: '21日', value: 21 },
  { name: '22日', value: 22 },
  { name: '23日', value: 23 },
  { name: '24日', value: 24 },
  { name: '25日', value: 25 },
  { name: '26日', value: 26 },
  { name: '27日', value: 27 },
  { name: '28日', value: 28 },
];

export const prefectureMenus = [
  { name: '北海道', value: '北海道' },
  { name: '青森県', value: '青森県' },
  { name: '岩手県', value: '岩手県' },
  { name: '宮城県', value: '宮城県' },
  { name: '秋田県', value: '秋田県' },
  { name: '山形県', value: '山形県' },
  { name: '福島県', value: '福島県' },
  { name: '茨城県', value: '茨城県' },
  { name: '栃木県', value: '栃木県' },
  { name: '群馬県', value: '群馬県' },
  { name: '埼玉県', value: '埼玉県' },
  { name: '千葉県', value: '千葉県' },
  { name: '東京都', value: '東京都' },
  { name: '神奈川県', value: '神奈川県' },
  { name: '新潟県', value: '新潟県' },
  { name: '富山県', value: '富山県' },
  { name: '石川県', value: '石川県' },
  { name: '福井県', value: '福井県' },
  { name: '山梨県', value: '山梨県' },
  { name: '長野県', value: '長野県' },
  { name: '岐阜県', value: '岐阜県' },
  { name: '静岡県', value: '静岡県' },
  { name: '愛知県', value: '愛知県' },
  { name: '三重県', value: '三重県' },
  { name: '滋賀県', value: '滋賀県' },
  { name: '京都府', value: '京都府' },
  { name: '大阪府', value: '大阪府' },
  { name: '兵庫県', value: '兵庫県' },
  { name: '奈良県', value: '奈良県' },
  { name: '和歌山県', value: '和歌山県' },
  { name: '鳥取県', value: '鳥取県' },
  { name: '島根県', value: '島根県' },
  { name: '岡山県', value: '岡山県' },
  { name: '広島県', value: '広島県' },
  { name: '山口県', value: '山口県' },
  { name: '徳島県', value: '徳島県' },
  { name: '香川県', value: '香川県' },
  { name: '愛媛県', value: '愛媛県' },
  { name: '高知県', value: '高知県' },
  { name: '福岡県', value: '福岡県' },
  { name: '佐賀県', value: '佐賀県' },
  { name: '長崎県', value: '長崎県' },
  { name: '熊本県', value: '熊本県' },
  { name: '大分県', value: '大分県' },
  { name: '宮崎県', value: '宮崎県' },
  { name: '鹿児島県', value: '鹿児島県' },
  { name: '沖縄県', value: '沖縄県' },
];

export const thingFilterMenus = [
  { name: 'すべて', value: 0 },
  { name: 'ナースコール', value: 'nc' },
  { name: 'スピーカー', value: 'sp' },
  { name: '自動火災報知器', value: 'afa' },
  { name: '自動ドア', value: 'ad' },
  { name: 'エレベーター', value: 'elv' },
  { name: '内線通報装置', value: 'tp' },
  { name: '無線給電装置', value: 'wps' },
  { name: 'セキュリティゲート', value: 'sg' },
];

export const thingMenus = [
  { name: 'ナースコール', value: 'nc' },
  { name: 'スピーカー', value: 'sp' },
  { name: '自動火災報知器', value: 'afa' },
  { name: '自動ドア', value: 'ad' },
  { name: 'エレベーター', value: 'elv' },
  { name: '内線通報装置', value: 'tp' },
  { name: '無線給電装置', value: 'wps' },
  { name: 'セキュリティゲート', value: 'sg' },
];

export const roleMenus = [
  { name: 'プラットフォーム管理者', kind: SUBTITLE, entryRole: [SUPER_USER, EMPLOYEE, SUPPORT], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT] },
  { name: 'RoboHUB開発者', value: SUPER_USER, kind: OPTION, entryRole: [SUPER_USER], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT] },
  { name: '社内関係者', value: EMPLOYEE, kind: OPTION, entryRole: [SUPER_USER, EMPLOYEE], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT] },
  { name: 'サポートセンター', value: SUPPORT, kind: OPTION, entryRole: [SUPER_USER, SUPPORT], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT] },
  { name: '施設管理者', kind: SUBTITLE, entryRole: [SUPER_USER, FAC_OWNER, FAC_ADMIN, FAC_USER], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER] },
  { name: '施主オーナー', value: FAC_OWNER, kind: OPTION, entryRole: [SUPER_USER, FAC_OWNER], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER] },
  {
    name: 'ロボット施設管理者',
    value: FAC_ADMIN,
    kind: OPTION,
    entryRole: [SUPER_USER, FAC_OWNER, FAC_ADMIN],
    filterRole: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER],
  },
  {
    name: '施設内ユーザ',
    value: FAC_USER,
    kind: OPTION,
    entryRole: [SUPER_USER, FAC_OWNER, FAC_ADMIN, FAC_USER],
    filterRole: [SUPER_USER, EMPLOYEE, SUPPORT, FAC_OWNER, FAC_ADMIN, FAC_USER],
  },
  { name: 'フリートマネージャー/ロボットメーカー', kind: SUBTITLE, entryRole: [SUPER_USER, ROBOT_ADMIN], filterRole: [SUPER_USER, EMPLOYEE, SUPPORT, ROBOT_ADMIN] },
  {
    name: 'フリートマネージャー/ロボットメーカー担当者',
    value: ROBOT_ADMIN,
    kind: OPTION,
    entryRole: [SUPER_USER, ROBOT_ADMIN],
    filterRole: [SUPER_USER, EMPLOYEE, SUPPORT, ROBOT_ADMIN],
  },
];

// ログ詳細での時間範囲設定
export const logTimeRange = [
  { name: '過去5分', value: 'PT5M' },
  { name: '過去30分', value: 'PT30M' },
  { name: '過去1時間', value: 'PT1H' },
  { name: '過去4時間', value: 'PT4H' },
  { name: '過去12時間', value: 'PT12H' },
  { name: '過去24時間', value: 'P1D' },
  { name: '過去48時間', value: 'P2D' },
  { name: '過去3日', value: 'P3D' },
  { name: '過去7日', value: 'P7D' },
  { name: 'カスタム', value: 'CUSTOM' },
];

// ログ種類
export const logKind = [
  { name: 'IM稼働ログ', value: ALL_LOG },
  { name: 'メール送信ログ', value: MAILSEND_LOG },
];

// ログレベル
export const logLevel = [
  { name: 'INFO, WARNING, ERROR', value: 1 },
  { name: 'WARNING, ERROR', value: 2 },
  { name: 'ERROR', value: 3 },
];

export const sgSimSettingMenus = [
  { name: '入場方向: 通行許可モード、退場方向: 通行許可モード', value: 'security' },
  { name: '入場方向: 通行許可モード、退場方向: 自動ドアモード', value: 'autoExit' },
  { name: '入場方向: 自動ドアモード、退場方向: 通行許可モード', value: 'autoEnter' },
  { name: '入場方向: 自動ドアモード、退場方向: 自動ドアモード', value: 'auto' },
  { name: '一斉開放モード', value: 'simulOpen' },
];

export const forcedUnlockMenus = [
  { name: '通常モード', value: '2' },
  { name: '強制解錠モード', value: '1' },
];

export const operableFlgMenus = [
  { name: '使用可', value: '1' },
  { name: '使用不可', value: '2' },
];

export const timeoutDelayTypeMenus = [
  { name: 'デフォルト', value: '1' },
  { name: 'カスタマイズ', value: '2' },
];
