import {
  INIT_SUMMARY_MONITOR_CHILD_DEVICE_DATUM,
  LOAD_SUMMARY_MONITOR_CHILD_DEVICE_DATUM,
  INIT_MONITOR_CHILD_DEVICE_LOGS,
  LOAD_MONITOR_CHILD_DEVICE_LOGS,
  INIT_MONITOR_RAW_LOGS,
  LOAD_MONITOR_RAW_LOGS,
} from '../../constants/ActionTypes';

const INIT_STATE = {
  summaryMonitorDeviceData: null,
  summaryMonitorSystemDatum: null,
  summaryMonitorChildDeviceDatum: null,
  summaryMonitorChildDeviceDatumLoaded: { value: true, message: '' },
  monitorChildDeviceLogs: null,
  monitorChildDeviceLogsLoaded: { value: true, message: '' },
  monitorRawLogKind: null,
  monitorRawLogs: null,
  monitorRawLogsLoaded: { value: true, message: '' },
  latestDateTime: '',
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SUMMARY_MONITOR_CHILD_DEVICE_DATUM: {
      return {
        ...state,
        summaryMonitorChildDeviceDatum: action.payload?.childDeviceDatum || null,
        summaryMonitorDeviceData: action.payload?.deviceData || null,
        summaryMonitorSystemDatum: action.payload?.systemDatum || null,
        latestDateTime: action.payload?.latestDateTime || '',
      };
    }
    case LOAD_SUMMARY_MONITOR_CHILD_DEVICE_DATUM: {
      return {
        ...state,
        summaryMonitorChildDeviceDatumLoaded: action.payload,
      };
    }
    case INIT_MONITOR_CHILD_DEVICE_LOGS: {
      return {
        ...state,
        monitorChildDeviceLogs: action.payload?.logs || null,
        latestDateTime: action.payload?.latestDateTime || '',
      };
    }
    case LOAD_MONITOR_CHILD_DEVICE_LOGS: {
      return {
        ...state,
        monitorChildDeviceLogsLoaded: action.payload,
      };
    }
    case INIT_MONITOR_RAW_LOGS: {
      return {
        ...state,
        monitorRawLogKind: action.payload?.logKind || null,
        monitorRawLogs: action.payload?.logs || null,
        latestDateTime: action.payload?.latestDateTime || '',
      };
    }
    case LOAD_MONITOR_RAW_LOGS: {
      return {
        ...state,
        monitorRawLogsLoaded: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
