import {
  INIT_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_ERROR_DATA,
  LOAD_ORGANIZATION_DATA,
  INIT_DEVICE_MAKER_DATA,
  UPDATE_DEVICE_MAKER_DATA,
  UPDATE_DEVICE_MAKER_ERROR_DATA,
  LOAD_DEVICE_MAKER_DATA,
  INIT_SITE_DATA,
  UPDATE_SITE_DATA,
  UPDATE_SITE_ERROR_DATA,
  LOAD_SITE_DATA,
  INIT_DEVICE_DATA,
  UPDATE_DEVICE_DATA,
  UPDATE_DEVICE_ERROR_DATA,
  LOAD_DEVICE_DATA,
  INIT_FACILITY_DATA,
  UPDATE_FACILITY_DATA,
  UPDATE_FACILITY_ERROR_DATA,
  LOAD_FACILITY_DATA,
  INIT_CHILD_DEVICE_DATA,
  UPDATE_CHILD_DEVICE_DATA,
  UPDATE_CHILD_DEVICE_ERROR_DATA,
  LOAD_CHILD_DEVICE_DATA,
  INIT_ACCOUNT_DATA,
  UPDATE_ACCOUNT_DATA,
  UPDATE_ACCOUNT_ERROR_DATA,
  LOAD_ACCOUNT_DATA,
  INIT_COOPERATION_SYSTEM_DATA,
  UPDATE_COOPERATION_SYSTEM_DATA,
  UPDATE_COOPERATION_SYSTEM_ERROR_DATA,
  LOAD_COOPERATION_SYSTEM_DATA,
  INIT_DIAMOND_CONNECTION_DATA,
  UPDATE_DIAMOND_CONNECTION_DATA,
  UPDATE_DIAMOND_CONNECTION_ERROR_DATA,
  LOAD_DIAMOND_CONNECTION_DATA,
  INIT_DIAMOND_FACILITY_DATA,
  UPDATE_DIAMOND_FACILITY_DATA,
  UPDATE_DIAMOND_FACILITY_ERROR_DATA,
  LOAD_DIAMOND_FACILITY_DATA,
  INIT_DIAMOND_DEVICE_DATA,
  UPDATE_DIAMOND_DEVICE_DATA,
  UPDATE_DIAMOND_DEVICE_ERROR_DATA,
  LOAD_DIAMOND_DEVICE_DATA,
  INIT_MASTER_COOPERATION_SYSTEM_DATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_DATA,
  UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_DATA,
  LOAD_MASTER_COOPERATION_SYSTEM_DATA,
} from 'constants/ActionTypes';
import { validator } from 'routes/Common/ValidatorRules';

export const initOrganizationData = (organizationData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_ORGANIZATION_DATA,
      payload: organizationData,
    });
  };
};

export const updateOrganizationData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ORGANIZATION_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.organizationData;
    dispatch(updateOrganizationErrorData(key, validator('edt_organization_' + key, value, editingObj)));
  };
};

export const updateOrganizationErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ORGANIZATION_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateOrganizationDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_ORGANIZATION_DATA,
      payload: { value, message },
    });
  };
};

export const initDeviceMakerData = (deviceMakerData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DEVICE_MAKER_DATA,
      payload: deviceMakerData,
    });
  };
};

export const updateDeviceMakerData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_DEVICE_MAKER_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.deviceMakerData;
    dispatch(updateDeviceMakerErrorData(key, validator('edt_device_maker_' + key, value, editingObj)));
  };
};

export const updateDeviceMakerErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_MAKER_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateDeviceMakerDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DEVICE_MAKER_DATA,
      payload: { value, message },
    });
  };
};

export const initSiteData = (siteData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_SITE_DATA,
      payload: siteData,
    });
  };
};

export const updateSiteData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_SITE_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.siteData;
    dispatch(updateSiteErrorData(key, validator('edt_site_' + key, value, editingObj)));
  };
};

export const updateSiteErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SITE_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateSiteDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_SITE_DATA,
      payload: { value, message },
    });
  };
};

export const initDeviceData = (deviceData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DEVICE_DATA,
      payload: deviceData,
    });
  };
};

export const updateDeviceData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_DEVICE_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.deviceData;
    dispatch(updateDeviceErrorData(key, validator('edt_device_' + key, value, editingObj)));
  };
};

export const updateDeviceErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DEVICE_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateDeviceDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DEVICE_DATA,
      payload: { value, message },
    });
  };
};

export const initFacilityData = (facilityData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_FACILITY_DATA,
      payload: facilityData,
    });
  };
};

export const updateFacilityData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_FACILITY_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.facilityData;
    dispatch(updateFacilityErrorData(key, validator('edt_facility_' + key, value, editingObj)));
  };
};

export const updateFacilityErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FACILITY_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateFacilityDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_FACILITY_DATA,
      payload: { value, message },
    });
  };
};

export const initChildDeviceData = (ChildDeviceData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_CHILD_DEVICE_DATA,
      payload: ChildDeviceData,
    });
  };
};

export const updateChildDeviceData = (key, value) => {
  return (dispatch, getState) => {
    const editingObj = getState().edit?.childDeviceData;
    let payload = { [key]: value };

    if (key.includes('timeout_delay')) {
      const tdKey = key.replace('timeout_delay_', '');
      const timeout_delay = { ...editingObj?.timeout_delay, [tdKey]: value };

      dispatch({
        type: UPDATE_CHILD_DEVICE_DATA,
        payload: { timeout_delay },
      });
      // バリデーション
      dispatch(updateChildDeviceErrorData(key, validator(key, value, editingObj)));

      return;
    }

    if (key.includes('limit_try_count')) {
      const ltcKey = key.replace('limit_try_count_', '');
      const limit_try_count = { ...editingObj?.limit_try_count, [ltcKey]: value };

      dispatch({
        type: UPDATE_CHILD_DEVICE_DATA,
        payload: { limit_try_count },
      });
      // バリデーション
      dispatch(updateChildDeviceErrorData(key, validator(key, value, editingObj)));

      return;
    }

    if (key === 'thing') payload = { ...payload, timeout_delay: {}, limit_try_count: {} };

    dispatch({
      type: UPDATE_CHILD_DEVICE_DATA,
      payload,
    });
    // バリデーション
    dispatch(updateChildDeviceErrorData(key, validator('edt_child_device_' + key, value, editingObj)));
  };
};

export const updateChildDeviceErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CHILD_DEVICE_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateChildDeviceDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_CHILD_DEVICE_DATA,
      payload: { value, message },
    });
  };
};

export const initAccountData = (accountData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_ACCOUNT_DATA,
      payload: accountData,
    });
  };
};

export const updateAccountData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ACCOUNT_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.accountData;
    dispatch(updateAccountErrorData(key, validator('edt_account_' + key, value, editingObj)));
  };
};

export const updateAccountErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ACCOUNT_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateAccountDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_ACCOUNT_DATA,
      payload: { value, message },
    });
  };
};

export const initCooperationSystemData = (cooperationSystemData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_COOPERATION_SYSTEM_DATA,
      payload: cooperationSystemData,
    });
  };
};

export const updateCooperationSystemData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_COOPERATION_SYSTEM_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.cooperationSystemData;
    dispatch(updateCooperationSystemErrorData(key, validator('edt_cooperation_system_' + key, value, editingObj)));
  };
};

export const updateCooperationSystemErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COOPERATION_SYSTEM_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateCooperationSystemDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_COOPERATION_SYSTEM_DATA,
      payload: { value, message },
    });
  };
};

export const initDiamondConnectionData = (diamondConnectionData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DIAMOND_CONNECTION_DATA,
      payload: diamondConnectionData,
    });
  };
};

export const updateDiamondConnectionData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_DIAMOND_CONNECTION_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.diamondConnectionData;
    dispatch(updateDiamondConnectionErrorData(key, validator('edt_diamond_connection_' + key, value, editingObj)));
  };
};

export const updateDiamondConnectionErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_CONNECTION_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateDiamondConnectionDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DIAMOND_CONNECTION_DATA,
      payload: { value, message },
    });
  };
};

export const initDiamondFacilityData = (diamondFacilityData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DIAMOND_FACILITY_DATA,
      payload: diamondFacilityData,
    });
  };
};

export const updateDiamondFacilityData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_DIAMOND_FACILITY_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.diamondFacilityData;
    dispatch(updateDiamondFacilityErrorData(key, validator('edt_diamond_facility_' + key, value, editingObj)));
  };
};

export const updateDiamondFacilityErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_FACILITY_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateDiamondFacilityDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DIAMOND_FACILITY_DATA,
      payload: { value, message },
    });
  };
};

export const initDiamondDeviceData = (diamondDeviceData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_DIAMOND_DEVICE_DATA,
      payload: diamondDeviceData,
    });
  };
};

export const updateDiamondDeviceData = (key, value) => {
  return (dispatch, getState) => {
    const editingObj = getState().edit?.diamondDeviceData;
    let payload = { [key]: value };

    if (key.includes('timeout_delay')) {
      const tdKey = key.replace('timeout_delay_', '');
      const timeout_delay = { ...editingObj?.timeout_delay, [tdKey]: value };

      dispatch({
        type: UPDATE_DIAMOND_DEVICE_DATA,
        payload: { timeout_delay },
      });
      // バリデーション
      dispatch(updateDiamondDeviceErrorData(key, validator(key, value, editingObj)));

      return;
    }

    if (key.includes('limit_try_count')) {
      const ltcKey = key.replace('limit_try_count_', '');
      const limit_try_count = { ...editingObj?.limit_try_count, [ltcKey]: value };

      dispatch({
        type: UPDATE_DIAMOND_DEVICE_DATA,
        payload: { limit_try_count },
      });
      // バリデーション
      dispatch(updateDiamondDeviceErrorData(key, validator(key, value, editingObj)));

      return;
    }

    dispatch({
      type: UPDATE_DIAMOND_DEVICE_DATA,
      payload,
    });
    // バリデーション
    dispatch(updateDiamondDeviceErrorData(key, validator('edt_diamond_device_' + key, value, editingObj)));
  };
};

export const updateDiamondDeviceErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DIAMOND_DEVICE_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateDiamondDeviceDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_DIAMOND_DEVICE_DATA,
      payload: { value, message },
    });
  };
};

export const initMasterCooperationSystemData = (masterCooperationSystemData) => {
  return (dispatch) => {
    dispatch({
      type: INIT_MASTER_COOPERATION_SYSTEM_DATA,
      payload: masterCooperationSystemData,
    });
  };
};

export const updateMasterCooperationSystemData = (key, value) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_MASTER_COOPERATION_SYSTEM_DATA,
      payload: { [key]: value },
    });
    // バリデーション
    const editingObj = getState().edit?.masterCooperationSystemData;
    dispatch(updateMasterCooperationSystemErrorData(key, validator('edt_master_cooperation_system_' + key, value, editingObj)));
  };
};

export const updateMasterCooperationSystemErrorData = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MASTER_COOPERATION_SYSTEM_ERROR_DATA,
      payload: { [key]: value },
    });
  };
};

export const updateMasterCooperationSystemDataLoaded = (value, message = '') => {
  return (dispatch) => {
    dispatch({
      type: LOAD_MASTER_COOPERATION_SYSTEM_DATA,
      payload: { value, message },
    });
  };
};
