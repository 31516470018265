import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, closeSnackbar } from 'redux/actions';
import Snackbar from 'routes/Common/Snackbar';

const SnackBarControl = ({ children }) => {
  const dispatch = useDispatch();
  const timer = useRef(null);
  const { error, message } = useSelector(({ common }) => common);

  useEffect(() => {
    if (!!error) {
      timer.current = setTimeout(() => {
        dispatch(fetchError(''));
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!!message) {
      timer.current = setTimeout(() => {
        dispatch(closeSnackbar());
      }, 4000);
    } else {
      clearTimeout(timer.current);
      timer.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const onClose = () => {
    clearTimeout(timer.current);
    dispatch(closeSnackbar());
  };

  return (
    <Fragment>
      {!!error && <Snackbar open={Boolean(error)} message={error} severty={'error'} onClose={onClose} />}
      {!!message && <Snackbar open={Boolean(!!message)} message={message.message} severty={message.severty} onClose={onClose} />}
      {children}
    </Fragment>
  );
};
export default SnackBarControl;
