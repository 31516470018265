import { CurrentAuthMethod } from '../../../../constants/AppConstants';
import { AuhMethods } from '../../../../services/auth';
import CmtAvatar from '@coremat/CmtAvatar';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { useTheme } from '@emotion/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PersonIcon from '@mui/icons-material/Person';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { useDispatch } from 'react-redux';
import useClasses from 'theme/useClasses';

const styles = (theme) => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
});

const actionsList = [
  {
    icon: <PersonIcon />,
    label: 'Account',
  },
  {
    icon: <ExitToAppIcon />,
    label: 'Logout',
  },
];

const UserDropDown = () => {
  const theme = useTheme();
  const classes = useClasses(styles(theme));
  const dispatch = useDispatch();

  const onItemClick = (item) => {
    if (item.label === 'Logout') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  return (
    <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
      <CmtDropdownMenu onItemClick={onItemClick} TriggerComponent={<CmtAvatar size="small" src={'https://via.placeholder.com/150'} />} items={actionsList} />
    </Box>
  );
};

export default UserDropDown;
