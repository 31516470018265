import LayoutContext from '../../CmtLayouts/LayoutContext';
import SidebarThemeContext from '../../CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { List } from '@mui/material';
import clsx from 'clsx';
import React, { cloneElement, isValidElement, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import useClasses from 'theme/useClasses';

const styles = (sidebarTheme) => ({
  navMenuItem: {
    padding: '0 16px 0 0',
    position: 'relative',
    // miniの時にhoverで開くのを無効化
    // '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //   paddingLeft: 16,
    // },
    '.Cmt-miniLayout &': {
      paddingLeft: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 16px 9px 32px',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    color: sidebarTheme.textColor,
    '&:hover, &:focus': {
      color: sidebarTheme.textDarkColor,
      backgroundColor: sidebarTheme.navHoverBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: sidebarTheme.textDarkColor,
      },
    },
    '&.active': {
      color: sidebarTheme.textActiveColor,
      backgroundColor: sidebarTheme.navActiveBgColor,
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: sidebarTheme.textActiveColor,
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: sidebarTheme.textActiveColor,
        },
      },
    },
    // miniの時にhoverで開くのを無効化
    // '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //   justifyContent: 'center',
    //   padding: 0,
    //   height: 40,
    //   width: 40,
    //   borderRadius: '50%',
    //   marginLeft: 4,
    //   marginRight: 4,
    // },
    '.Cmt-miniLayout &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },
  },
  navText: {
    flex: 1,
    fontSize: 14,
    // 文字部分だけアニメーション追加
    animationName: 'fadein',
    animationDuration: '1.5s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
    '@keyframes fadein': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    // miniの時にhoverで開くのを無効化
    // '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //   display: 'none',
    // },
    '.Cmt-miniLayout &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    // miniの時にhoverで開くのを無効化
    // '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
    //   marginRight: 0,
    // },
    '.Cmt-miniLayout &': {
      marginRight: 0,
    },
  },
});

const NavMenuItem = (props) => {
  const { setSidebarOpen } = useContext(LayoutContext);
  const { name, icon, link } = props;
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useClasses(styles(sidebarTheme));

  const renderIcon = () => {
    if (icon && isValidElement(icon)) {
      return cloneElement(icon, {
        className: clsx(classes.iconRoot, 'Cmt-icon-root'),
      });
    }

    return null;
  };

  return (
    <List component="div" className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}>
      <NavLink className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')} to={link} onClick={() => setSidebarOpen(false)}>
        {/* Display an icon if any */}
        {renderIcon()}
        <span className={clsx(classes.navText, 'Cmt-nav-text')}>{name}</span>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
