import CmtSearch from '../../../../@coremat/CmtSearch';
import SearchIcon from '@mui/icons-material/Search';
import { Popover } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const SearchPopover = ({ iconClassName, popoverClassName }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenSearchBar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSearchBar = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <IconButton className={iconClassName} onClick={handleOpenSearchBar} size="large">
        <SearchIcon />
      </IconButton>
      <Popover
        className={popoverClassName}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseSearchBar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CmtSearch />
      </Popover>
    </React.Fragment>
  );
};

export default SearchPopover;
